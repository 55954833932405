@import "@/styles/_variables.less";

.sidebar {
  .container {
    .fp-container {
      &.logo {
        border-bottom: none;
        width: 100%;
        max-width: @sidebar-width;
        position: sticky !important;
        top: 0;
        left: 0;
        z-index: 100;

        .container-inner {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: @sidebar-background;
          height: 100%;

          .logo-container {
            color: white;

            .logo-name {
              font-family: 'PT Sans Caption', sans-serif;
              line-height: 32px;
              font-size: 1.5rem;
              margin-top: 1rem;
              margin-bottom: 0;

              img {
                height: 35px;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}
