@import '@/styles/_variables.less';

.device-status-overview-history {
  &.main-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 850px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    .table-container {
      width: 720px;
      padding: 0.25rem;

      @media (max-width: 850px) {
        width: 100%;
        padding: 0;
      }

      .p-datatable-table {
        .p-datatable-tbody {
          td {
            padding: 0.25rem 0.5rem;

            &.status-column {
              padding: 0.25rem 1.25rem;
            }

            .device-name.p-button {
              font-size: 0.85rem;
              padding: 0;
              text-align: left;
              width: 100%;
              color: #3f3f46;
              height: auto;

              >span {
                font-weight: normal;
                text-decoration: underline;
              }
            }
          }
        }
      }

      // for stack mode
      .p-datatable .p-datatable-tbody>tr>td>.p-column-title {
        display: none;
        font-weight: 600;
      }

      @media screen and (max-width: 960px) {

        .p-datatable .p-datatable-thead>tr>th,
        .p-datatable .p-datatable-tfoot>tr>td {
          display: none !important;
        }

        .p-datatable .p-datatable-tbody>tr>td:not(:last-child) {
          border: 0 none;
        }

        .p-datatable .p-datatable-tbody>tr>td {
          display: flex;
          width: 100% !important;
          align-items: center;
          justify-content: space-between !important;
          padding: 0.25rem 1rem;
          border: 0;

          .device-name.p-button {
            width: auto;
          }

          .status-icon {
            margin-left: auto;
            margin-right: 0;
          }

          &.status-column {
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }

        .p-datatable .p-datatable-tbody>tr>td>.p-column-title {
          display: block;
        }
      }

      // for stack mode

      &.history {
        width: 100% !important;
      }

      .status-icon {
        width: 28px;
        height: 28px;
        background-color: white;
        border: 1px solid #555555;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;

        &.status-online {
          background-color: #008000;
        }

        &.status-offline {
          background-color: #ff0000;
        }

        &.status-dormant {
          background-color: #d3d3d3;
        }

        &.status-alerting {
          background-color: #ffd700;
        }

        &.status-unknown {
          background-color: #000000;
        }
      }

      .p-filter-column {
        .p-inputtext {
          padding: 0.25rem 0.5rem;
          border: 1px solid #d4d4d8;
          height: 34px;
        }

        .p-column-filter-element {
          width: 100%;
        }

        .p-column-filter-menu-button {
          display: none;
        }

        .p-column-filter-clear-button {
          display: none;
        }
      }

      .status-container {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        overflow-x: hidden;
      }

      .child-div {
        height: 28px;
        top: 0px;
        bottom: 0px;
        z-index: 0 !important;
        border: 0;
      }

      .child-div.status-online {
        background-color: #008000;
      }

      .child-div.status-offline {
        background-color: #ff0000;
      }

      .child-div.status-dormant {
        background-color: #d3d3d3;
      }

      .child-div.status-alerting {
        background-color: #ffd700;
      }

      .child-div.status-unknown {
        background-color: #000000;
      }

      .child-div.status-no-data {
        background-color: #d3d3d3;
      }
    }
  }
}

.tooltip-content {
  &.device-status-overview-history {
    .tooltip-content-item {
      gap: 1rem;
      font-weight: normal;
      align-items: flex-start !important;

      &.timestamp {
        justify-content: center !important;
      }

      .status {
        &.status-online {
          color: #008000;
        }

        &.status-offline {
          color: #ff0000;
        }

        &.status-dormant {
          color: #d3d3d3;
        }

        &.status-alerting {
          color: #ffd700;
        }

        &.status-unknown {
          color: #000000;
        }
      }
    }
  }
}

.p-overlaypanel.p-component {
  &.overlay-panel-device-history {
    &::before {
      border-bottom-color: #555555;
    }

    &::after {
      border-bottom-color: #F5F5F5;
    }

    background-color: #F5F5F5;
    border: 2px solid #555555;
    box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);
    max-width: 80vw;

    .overlay-panel-content-container {
      .row-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
        font-size: 1rem;
        margin-top: 0.25rem;
        padding-bottom: 0.25rem;

        &:last-child {
          border-bottom: none;
        }

        .status-item {
          padding: 0.125rem 0.5rem;
          font-weight: bolder;
          font-size: 0.875rem;

          &.status-online {
            color: #008000;
          }

          &.status-offline {
            color: #ff0000;
          }

          &.status-dormant {
            color: #d3d3d3;
          }

          &.status-alerting {
            color: #ffd700;
          }

          &.status-unknown {
            color: #000000;
          }
        }
      }
    }
  }
}