.custom-dropdown {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;

  .title {
    font-size: 0.875rem;
    padding: 0;
    margin-bottom: -4px;
    z-index: 2;
    color: #333333;
  }

  .p-dropdown {
    border: 0;

    .p-dropdown-label {
      padding: 0.25rem;
    }

    .p-dropdown-trigger {
      display: none;
    }

    &.p-inputwrapper {
      height: 32px;
      width: 90px;
      border: 0;
      border-bottom: 1px solid #dddddd;
      border-radius: 0;

      .p-dropdown-label.p-inputtext {
        box-shadow: none;
        justify-content: flex-start;
        padding: 0;
        font-weight: 700;
      }
    }

    &.p-inputwrapper-focus {
      box-shadow: none;
    }
  }
}