@import '@/styles/_variables.less';

.signage-components-notice-container {
  &.main-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .input-container {
      display: flex;
      padding: 0.25rem;

      .p-inputtext {
        width: 962px;
        height: 102px;
        max-width: 962px;
        max-height: 102px;
      }
    }

    .validation-error-message {
      font-size: 0.75rem;
      text-align: start;
      color: @custom-red;
      margin: 0px 0px 10px 5px;
    }

    .button-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 1.5rem;
      width: 100%;
      max-width: 100%;

      &.is-submit-button-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: flex-start;

        .submit-button {
          margin-right: 1rem;
          width: 100px;
        }
      }
    }
  }
}
