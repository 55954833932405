@import "@/styles/_variables.less";
// SideBar stylesheet

.sidebar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

&.active .sidebar-container {
  color: @primary-color;
  background-color: @white;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px #00000040;
  padding: 0px, 8px, 0px, 8px;
}

.toggle-pc-mobile-container {
  margin-top: 1rem;
  padding-left: 0.25rem;
  display: block;

  .username-logout {
    color: @custom-red;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.42px;
    text-align: left;
    margin: 0;
    cursor: pointer;
  }
}