@import "@/styles/_variables.less";

.header {
  .container {
    .fp-container {
      &.logo-mobile {
        display: flex;
        border-bottom: none;
        width: 100%;
        max-width: @sidebar-width;
        margin-left: 1rem;

        .container-inner {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: @sidebar-background;
          height: 100%;

          .logo-container {
            color: white;

            .logo-name {
              font-family: 'PT Sans Caption', sans-serif;
              line-height: 32px;
              font-size: 1.5rem;
              margin: 0;

              img {
                height: 20px;
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  &.header--sidebar-hidden {
    .fp-container {
      &.logo-mobile {
        margin-left: 0;
      }
    }
  }
}