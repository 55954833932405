.fp-loader-spinner {
  width: 100%;
  height: 100%;
  position:relative;
  min-width: 40px;
  min-height: 40px;
}

.fp-loader-spinner-content {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%); 
  text-align:center;  
}

.fp-loader-spinner_bar {
  -webkit-animation: react-spinner_spin 1.2s linear infinite;
  -moz-animation: react-spinner_spin 1.2s linear infinite;
  animation: react-spinner_spin 1.2s linear infinite;
  border-radius: 5px;
  background-color: black;
  position: absolute;
  width: 20%;
  height: 7.8%;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%);
}

@keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

@-moz-keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

@-webkit-keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}
