@import '@/styles/_variables.less';

#imageMarkerContainerAreaMap {
    &.image-marker {
        position: relative;
        margin: 0 auto;

        &__image {
            margin: 0 auto;
            width: 100%;
        }

        &__marker {
            position: absolute;
        }

        &__marker--default {
            width: 25px;
            height: 25px;
            background-color: brown;
            border-radius: 50%;
            color: white;
            text-align: center;
        }
    }

    .area-marker__marker {
        .moveable-control-box {
            .moveable-control {
                background-color: @custom-red;
            }

            .moveable-line {
                background-color: @custom-red;
                height: 2px;
            }
        }

        .area-marker-target {
            background-color: rgba(16, 185, 129, 0.5);

            &.is-hovered {
                background-color: @custom-red-alpha-4;

                &.is-selected {
                    background-color: @custom-red-alpha-4;
                }
            }

            &.is-selected {
                background-color: @custom-red-alpha-4;
            }
        }
    }
}

.image-marker-tools.p-buttonset {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.25rem;

    .p-button {
        width: 34px;
        height: 34px;
    }

    @media (max-width: @mobile-size) {
        width: auto;
    }
}