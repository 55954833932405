@import '@/styles/_variables.less';

.employee-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;
    // overflow: scroll;

    .p-datatable {
      @media (max-width: @mobile-size) {
        min-width: 1280px;
      }

      &.p-datatable-sm {
        .p-datatable-thead {
          th {
            &.p-filter-column {
              .p-column-filter-menu-button {
                display: none;
              }
            }
          }
        }

        .p-datatable-tbody {
          tr {
            &.p-selectable-row {
              &:hover {
                color: @custom-selected-color !important;
                // font-weight: 700;
                font-size: 1.1rem;
              }
            }

            td {
              padding: 0.25rem 0.75rem;
              font-size: 0.875rem;
            }
          }
        }
      }

      .action-body-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.25rem;
      }

      .status-body-container {
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        .p-tag {
          width: 100%;
        }
      }
    }

    .main-button-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
      margin-bottom: 1rem;

      @media (max-width: @mobile-size) {
        min-width: 1280px;
      }

      .save-new-button-container {
        display: flex;
        align-items: center;
        gap: 2rem;

        .p-button {
          width: 100px;
        }
      }

      .csv-button-container {
        display: flex;
        align-items: center;
        gap: 2rem;
      }
    }
  }
}