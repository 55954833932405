@import '@/styles/_variables.less';

.network-container {
  &.network-performance-score {
    padding: 1rem;
    align-items: flex-start;

    .network-content {
      overflow: auto;
      background-color: white;
      margin-top: 1rem;
      padding-top: 0px !important;
    }

    .data-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-width: 1280px;
      flex-direction: row;
      flex-wrap: wrap;
      height: 540px;
      background-color: white;
      position: relative;

      .data-container__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0;
        padding: 0.5rem;

        &.data-container__item--heatmap {
          width: calc(100% - 440px + 90px);
        }

        &.data-container__item--barchart {
          width: 440px;
          margin-left: -135px;

          .title-chart-container {
            height: 0;
          }
        }

        .chart-container {
          width: 100%;
          z-index: 199;
          margin-top: -43px;

          .recharts-responsive-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .heatmap-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 200;

          .recharts-responsive-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          #chartdiv-heatmap {
            width: 100%;
            height: 500px;
          }
        }

        @media (max-width: 1280px) {
          width: 100%;
          height: auto;
        }
      }

      .load-more-button-container {
        position: absolute;
        bottom: 2rem;
        left: 1rem;

        .p-button-label {
          font-weight: bold;
        }
      }

      &.is-submit-button-container {
        padding-left: 0.5rem;
        margin-top: 1rem;
        justify-content: space-between;
        background-color: #174fe3;
        border-color: #174fe3;

        .csv-button-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .csv-button {
            width: 160px;
            margin-left: 1rem;
            background-color: #174fe3;
            border-color: #174fe3;
          }
        }
      }
    }

    .flex-container {
      display: flex;
      justify-content: space-between;
    }

    .text-left {
      text-align: right;
    }
  }
}
