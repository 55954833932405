@import "@/styles/_variables.less";

.username.username-desktop {

  .username-date {
    color: rgba(151, 167, 183, 0.43);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.62px;
    text-transform: uppercase;
    margin-bottom: 17px;
    text-align: left;

    &:after {
      content: "";
      width: 120px;
      height: 1px;
      border-bottom: 1px solid rgba(151, 167, 183, 0.43);
      display: inline-block;
      margin-left: 10px;
    }
  }

  .username-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .username-info {
      .username-name {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.46px;
        color: @white;
        text-transform: capitalize;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }

      .username-logout {
        color: @custom-red;
        font-size: 0.875rem;
        font-weight: bold;
        letter-spacing: 0.42px;
        text-align: left;
        margin: 0;
        cursor: pointer;
        text-align: center;

        &:hover {
          text-decoration: underline;
          background-color: @custom-red;
          color: white;
          padding-left: 0.25rem;
          padding-right: 0.25rem;
          border-radius: @custom-border-radius;
        }
      }
    }
  }
}
