@import '@/styles/_variables.less';

.air-quality-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;
    height: 100%;

    .panel {
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .title-text {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }

      .floor-list-container {
        min-width: 240px;
        max-width: 300px;

        .floor-list {
          padding: 0.5rem;
          height: auto;
          overflow: auto;
          border: 2px solid #0000005c;
          border-radius: @custom-border-radius;
          background-color: white;

          .floor-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: normal;
            text-align: left;
            padding: 0.5rem;
            font-weight: 500;
            font-size: 0.875rem;

            &.checked {
              color: @custom-selected-color;
              font-weight: bolder;
            }
          }
        }
      }

      .sensor-setting-container {
        width: 100%;
        min-width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .title-text {
          text-align: left;
        }

        .sensor-setting-list {
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 1rem;

          .sensor-setting-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .sensor-setting-label {
              min-width: 100px;
              width: 100px;
              text-align: left;
            }

            .sensor-setting-content {
              display: flex;
              width: calc(100% - 100px);
              align-items: center;
              justify-content: flex-start;

              .sensor-setting-content-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;
                max-width: 120px;

                .label {
                  font-size: 0.875rem;
                  text-align: left;
                  width: 60px;
                  min-height: 24px;
                  position: relative;

                  .error-label {
                    color: @custom-red;
                    position: absolute;
                    left: 2rem;
                    white-space: nowrap;
                    font-weight: 500;
                    background-color: white;
                    z-index: 100;
                  }

                  &-left {
                    left: 50%;
                  }

                  &-right {
                    right: 50%;
                  }
                }

                .value-input {
                  min-height: 40px;
                  z-index: 2;

                  .p-inputnumber-input {
                    width: 80px;
                    max-width: 80px;
                    text-align: center;

                    &.p-invalid {
                      border-width: 2px;
                    }
                  }

                  &-left {
                    margin-left: 120px;
                  }

                  &-right {
                    margin-right: 120px;
                  }
                }

                .color-line {
                  margin-top: 5px;
                  height: 10px;
                  min-height: 10px;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-start;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}