@import '@/styles/_variables.less';
// ConfigureBoard stylesheet

.network-container {
  &.wireless-signal {
    padding: 1rem;
    align-items: flex-start;

    @media (max-width: 1200px) {
      padding: 0;
    }

    .select-single-type-style {
      @media (max-width: 1200px) {
        margin-top: 8px;
      }
    }

    .heatmap-floormap-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-evenly;
      overflow-x: auto;

      @media (max-width: 1200px) {
        flex-wrap: wrap;
      }

      .heatmap-container {
        width: 50%;
        min-width: 500px;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: @custom-border-radius;
        min-height: 100px;
        margin-top: 3.3rem;
        flex-direction: column;
        overflow-x: auto;

        @media (max-width: 1200px) {
          width: 100%;
          min-width: 100%;
          margin-top: 0;
          margin-bottom: 4rem;
        }

        #chartdiv-heatmapwirelesssignal {
          width: 100%;
          height: 500px;
          padding-top: 0.5rem;

          @media (max-width: 1200px) {
            min-width: 700px;
          }
        }
      }

      .floormap-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        min-width: 500px;
        padding: 0.5rem;
        flex-direction: column;

        @media (max-width: 1200px) {
          width: 100%;
          min-width: 100%;
          padding: 0;
        }

        .image-marker {
          img {
            height: auto;
            width: 100%;
          }
        }

        .data-container-floormap {
          @media (max-width: 1200px) {
            width: 100%;
          }
        }
      }
    }
  }
}