@import '@/styles/_variables.less';
// ConfigureBoard stylesheet

.dashboard-container {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .dashboard-welcome-title-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .dashboard-content {
    margin-top: 100px;
    width: 100%;

    .menu-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      flex-wrap: nowrap;

      .menu-item {
        width: 100%;
        padding: 24px 10px;
        margin-right: auto;

        .menu-item__title {
          font-size: 1.5rem;
          font-weight: 500;
          margin-left: 1rem;

          a {
            color: @custom-selected-color;
          }
        }

        .menu-item__content {
          margin-top: 0.25rem;
        }
      }
    }
  }
}