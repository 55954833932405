@import '@/styles/_variables.less';

.config-container {
  &.bcp-overview {
    padding: 1rem;
    align-items: flex-start;

    @media (max-width: 850px) {
      padding: 0;
      flex-direction: row;
    }

    .config-title-container {
      .text-left {
        min-width: fit-content;
      }

      @media (max-width: 850px) {
        display: flex;
      }

      .config-custom-container {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media (max-width: 850px) {
          margin-top: 16px;
        }

        .multiselect-container {
          @media (max-width: 850px) {
            width: calc(100% - 90px);
          }
        }

        .btn-download {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding-right: 28px;
        }
      }
    }

    .config-content {
      justify-content: flex-start;
      height: 100%;

      @media (max-width: 850px) {
        overflow: auto;
      }

      .p-tabview {
        width: 100%;

        .p-tabview-nav-content {
          .p-tabview-nav {
            @media (max-width: 850px) {
              justify-content: center;
            }
          }

          button {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }
        }

        .p-tabview-panels {
          padding: 1rem 0.5rem 0.5rem 0.5rem;

          @media (max-width: 850px) {
            padding: 0;
          }
        }
      }
    }

    .main-container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: 100%;

      @media (max-width: 850px) {
        flex-direction: row;
      }

      .table-container {
        // width: 670px;
        padding: 0.25rem;

        @media (max-width: 850px) {
          width: 100%;
          padding: 0;
        }

        .p-filter-column {
          .p-inputtext {
            padding: 0.25rem 0.5rem;
            border: 1px solid #d4d4d8;
            height: 34px;
          }

          .p-column-filter-element {
            width: 100%;
          }

          .p-column-filter-menu-button {
            display: none;
          }

          .p-column-filter-clear-button {
            display: none;
          }
        }
      }
    }
  }
}
