@import '@/styles/_variables.less';

.employee-location-list-left-container {
  &.left-container {
    width: 100%;
    padding: 0.25rem;

    @media (max-width: @mobile-size) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      padding: 0.25rem;
    }

    .team-group-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 36px;
      height: auto;
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid @border-color;
      cursor: pointer;
    }

    .search-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 44px;
      padding: 5px 12px;
      background-color: #eeeeee;
      border-radius: 4px;
      border: 1px solid @border-color;
      gap: 20px;

      .input-container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .department-search,
        .team-search {
          width: 100%;
          border-radius: 4px;
          font-size: 0.8rem;
          border: 1px solid @border-color;
          height: 32px;
          padding-right: 35px;
        }
      }

      .icon-clear {
        cursor: pointer;
      }
    }

    .group {
      width: 100%;
      height: calc(100vh - 360px);
      overflow: auto;
      display: block;

      @media (max-width: @mobile-size) {
        height: auto;
      }
    }

    .group-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 36px;
      background: #f2f2f2;
      border-radius: 4px;
      border: 1px solid @border-color;
      padding: 0.25rem 0.875rem;
      font-weight: bold;

      .no-data-label {
        margin-left: auto;
        font-size: 0.875rem;
        font-style: italic;
        color: @grey;
        font-weight: normal;
      }
    }

    .toggle-icon {
      span {
        &.p-accordion-toggle-icon {
          display: none;
        }
      }
    }

    .cursor-accordion-icon {
      a {
        cursor: default;
        pointer-events: none;
      }
    }

    .department-container {
      overflow: hidden;

      .header-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .title-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-left: 1rem;
          padding: 0.125rem;
          text-align: left;
          line-height: 1.25;

          @media (max-width: @mobile-size) {
            font-size: 0.875rem;
            width: 100%;
          }

          .text-title {
            &.is-selected {
              color: @custom-selected-color;
            }

            &:hover {
              color: @custom-selected-color;
              font-weight: bold;
              text-decoration: underline;
            }
          }

          .department-name {
            cursor: pointer;
          }

          .count-title {
            margin-left: 1rem;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 0.85rem;
            line-height: 24px;

            @media (max-width: @mobile-size) {
              width: 20px;
              height: 20px;
              font-size: 0.75rem;
              margin-right: 0.5rem;
              margin-left: auto;
            }
          }
        }
      }
    }

    .p-accordion {
      .p-accordion-tab {
        .p-accordion-header {
          min-height: 36px;
          height: auto;

          span.fa {
            display: none;
          }

          .p-accordion-header-text {
            @media (max-width: @mobile-size) {
              width: 100%;
            }

            .header-container {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              .title-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 0.5rem;
                text-align: left;
                line-height: 1.25;

                @media (max-width: @mobile-size) {
                  font-size: 0.875rem;
                  width: 100%;
                }
              }
            }
          }

          cursor: default;

          .p-accordion-header-link {
            padding: 0.325rem;
            padding-right: 1rem;
            padding-left: 1rem;
            border-radius: 0.375rem;
            font-weight: normal;
            font-size: 0.9rem;
            height: 100%;
            flex-direction: row-reverse;
            justify-content: space-between;
            background: #ffffff;
            border: 1px solid @border-color;
          }

          &:focus {
            box-shadow: none;
          }
        }

        .p-toggleable-content {
          .p-accordion-content {
            padding: 0 3rem;
            padding-right: 0;
            border: 0;
            background-color: transparent;
          }
        }
      }
    }
  }
}