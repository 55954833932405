@import '@/styles/_variables.less';

.config-container {
  &.employee-attendance {
    @breakpoint-1200: 1200px;
    padding: 1rem;
    align-items: flex-start;

    .config-content {
      justify-content: flex-start;
    }

    .main-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;

      @media (max-width: @mobile-size) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
      }

      .chart-container {
        width: 100%;
        height: 100%;
        padding: 0.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        > div {
          width: 100%;
          height: auto;
        }

        .select-btn-container {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 20px;

          .select-chart-btn-container {
            flex: 1;
          }
        }

        .data-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .data-container__item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 0;
            padding: 0.5rem;
            width: 100%;

            @media (max-width: @mobile-size) {
              width: 100%;
              padding: 0;
            }

            .chart-container {
              margin-top: 0.5rem;
              width: 100%;
              margin-left: -3rem;

              .recharts-responsive-container {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .location-chart-view-container {
          width: 100%;
          height: calc(100% - 45px);
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 1rem;
          gap: 1rem;
          flex-wrap: nowrap;

          @media (max-width: @breakpoint-1200) {
            justify-content: flex-start;
            flex-wrap: wrap;
          }

          @media (max-width: calc(@mobile-sm-size + 21px)) {
            padding: 1rem 0.5rem;
            height: auto;
          }

          .title-container {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            font-size: 1rem;
            font-weight: bold;
            background-color: #dcdcdc;
            color: rgba(0, 0, 0, 0.87);
            padding: 0.5rem 0.75rem;
            border-radius: 0.25rem;
            box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
            align-items: center;

            .custom-legend-button {
              padding: 0;
              margin-left: 1rem;
              margin-top: 4px;
              color: #333333;
              height: 20px;
            }
          }

          .location-list-container {
            flex: 1;
            max-width: 400px;
            min-width: 400px;
            height: 100%;

            @media (max-width: @breakpoint-1200) {
              width: 100%;
              max-width: 100%;
              height: auto;
              min-width: auto;
              flex: unset;
            }

            .location-list {
              display: flex;
              flex-direction: column;
              padding-top: 0.5rem;
              gap: 0.5rem;
              height: 100%;
              max-height: calc(100% - 50px);
              overflow-y: auto;
              padding-bottom: 1rem;
              margin-top: 0.5rem;

              @media (max-width: @breakpoint-1200) {
                height: 500px;
                max-width: 500px;
                margin-left: auto;
                margin-right: auto;
              }

              @media (max-width: calc(@mobile-sm-size + 21px)) {
                height: 100%;
              }

              .location-item {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                padding: 0.25rem 1rem;
                border-radius: 5px;
                box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
                border: 1px solid @border-color;
                position: relative;
                min-height: 70px;

                &.location-zone {
                  &-green {
                    background-color: rgba(0, 168, 107, 1);
                    color: @white;

                    .item-name {
                      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
                    }

                    .rate {
                      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
                    }
                  }

                  &-lightgreen {
                    background-color: rgba(152, 214, 58, 0.8);

                    .item-name {
                      text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
                    }

                    .rate {
                      text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
                    }
                  }

                  &-yellow {
                    background-color: rgba(255, 225, 53, 0.8);

                    .item-name {
                      text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
                    }

                    .rate {
                      text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
                    }
                  }

                  &-orange {
                    background-color: rgba(255, 153, 68, 1);

                    .item-name {
                      text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
                    }

                    .rate {
                      text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
                    }
                  }

                  &-red {
                    background-color: rgba(210, 20, 4, 1);
                    color: @white;

                    .item-name {
                      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
                    }

                    .rate {
                      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
                    }
                  }
                }

                .left-container {
                  flex: 4;
                  height: 100%;

                  .item-name {
                    font-size: 1.1rem;
                    font-weight: 500;
                    text-align: left;
                    @media (max-width: calc(@mobile-sm-size + 21px)) {
                      font-size: 0.875rem;
                    }
                  }
                }

                .right-container {
                  flex: 2;
                  display: flex;
                  align-items: baseline;
                  justify-content: flex-end;
                  gap: 0.5rem;
                  height: 100%;

                  .rate {
                    flex: 1;
                    font-size: 1.75rem;
                    font-weight: 600;
                    text-align: right;

                    .percentage-symbol {
                      font-size: 1rem;
                    }
                  }

                  .count {
                    flex: 1;
                    font-size: 0.875rem;
                    font-weight: 400;
                    text-align: center;
                  }
                }

                .timestamp {
                  position: absolute;
                  font-size: 0.8rem;
                  text-align: right;
                  font-style: italic;
                  opacity: 0.8;
                  right: 1rem;
                  bottom: 0.125rem;
                }
              }
            }
          }

          .chart-view-container {
            flex: 1;
            max-width: calc(100% - 425px);

            @media (max-width: @breakpoint-1200) {
              width: 100%;
              max-width: 100%;
              flex: unset;
            }

            @media (max-width: calc(@mobile-sm-size + 21px)) {
              display: none;
            }

            .menu-container {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-top: 0.5rem;
              gap: 0 1rem;

              &.menu-container-1 {
                justify-content: flex-end;
              }

              .timerange-select-container {
                background: transparent;
                transition: none;
                border-radius: 0.375rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              span {
                &.p-multiselect-token-label {
                  width: 100px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }
}
