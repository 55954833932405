@import '@/styles/_variables.less';

.config-container {
  &.signage-components-container {
    .config-title-signage {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      justify-content: stretch;
      min-height: auto;
      margin-bottom: 1rem;

      .title-text {
        font-size: 1.25rem;
        font-weight: 500;
        text-align: flex-start;
        text-align: left;
      }
    }

    .config-content {
      justify-content: flex-start;
      overflow: hidden;

      .p-tabview {
        width: 100%;

        .p-tabview-nav-content {
          .p-tabview-nav {
            @media (max-width: 850px) {
              justify-content: center;
            }
          }

          button {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }
        }

        .p-tabview-panels {
          padding: 1rem 0.5rem 0.5rem 0.5rem;

          @media (max-width: 850px) {
            padding: 0;
          }
        }
      }
    }
  }
}
