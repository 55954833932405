@import '@/styles/_variables.less';

.bcp-overview-employee {
  &.overview-employee-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0.5rem;
    height: 100%;

    @media (max-width: 850px) {
      width: 50%;
      margin-left: 0.5rem;
    }

    .table-container {
      padding: 0.25rem;
      height: 100%;
      width: 100%;

      .p-datatable-table {
        .p-datatable-tbody {
          td {
            padding: 0.25rem 0.5rem;
          }
        }
      }

      .p-column-header-content {
        justify-content: center;
      }

      // for stack mode
      .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
        display: none;
        font-weight: 600;
      }

      .p-datatable .p-datatable-tbody > tr {
        height: 40px;

        &:hover {
          color: @custom-selected-color;
          font-weight: bold;
        }
      }

      .p-filter-column {
        .p-inputtext {
          padding: 0.25rem 0.5rem;
          border: 1px solid #d4d4d8;
          height: 34px;
        }

        .p-column-filter-element {
          width: 100%;
        }

        .p-column-filter-menu-button {
          display: none;
        }

        .p-column-filter-clear-button {
          display: none;
        }

        .p-dropdown {
          border: 1px solid #d4d4d8;
          background: #ffffff;

          .p-dropdown-label {
            padding: 0.25rem;
          }

          &.p-inputwrapper {
            height: 34px;
            .p-dropdown-label.p-inputtext {
              box-shadow: none;
              justify-content: flex-start;
              padding-right: 0;
              border: 0;
            }
          }

          &.p-inputwrapper-focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
