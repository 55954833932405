@import '@/styles/_variables.less';

.verkada-device-setting-container {

  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;
    overflow: auto;

    .panel {
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .title-text {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }

      .floor-list-container {
        min-width: 240px;
        width: 20%;
        max-width: 300px;

        .floor-list {
          padding: 0.5rem;
          height: auto;
          overflow: auto;
          border: 2px solid #0000005C;
          border-radius: @custom-border-radius;
          background-color: white;

          .floor-item {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            white-space: nowrap;
            padding: 0.5rem;
            font-weight: 500;
            font-size: 0.875rem;
            white-space: pre-line;
            text-align: left;
            margin: 3px 0px;
          }

          .height-light-style {
            background-color: #FA8286;
          }
        }
      }

      .verkada-device-container {
        min-width: 800px;
        width: 80%;
        overflow: auto;

        .title-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 1rem;

          .id-container {
            width: 30%;
            min-width: 320px;
          }

          .name-container {
            width: 30%;
          }

          .type-container {
            width: 20%;
            max-width: 120px;
          }

          .delete-container {
            width: 10%;
            max-width: 80px;
          }
        }

        .device-list-container {
          max-height: calc(100vh - 370px);
          min-height: 200px;
          padding-bottom: 60px;
          overflow: auto;

          .device-list {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 12px;
            gap: 1rem;
            background-color: white;

            .id-container {
              width: 30%;
              min-width: 320px;
              position: relative;
              padding: 0px 10px;

              .p-inputtext {
                width: 100%;
              }
            }

            .name-container {
              width: 30%;
              position: relative;
              padding: 0px 10px;

              .p-inputtext {
                width: 100%;
              }
            }

            .type-container {
              width: 20%;
              max-width: 120px;

              .p-dropdown {
                width: 100%;
              }
            }

            .delete-container {
              width: 10%;
              max-width: 80px;
            }

            .validation-error-message {
              font-size: 0.75rem;
              position: absolute;
              color: @custom-red;
            }
          }
        }
      }

    }

    .button-container {
      display: flex;
      justify-content: flex-start;
      margin-top: 1rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      gap: 2rem;

      .submit-button {
        width: 100px;
      }

      .p-button {
        width: 100px;
      }
    }

  }
}