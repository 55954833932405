@import '@/styles/_variables.less';

.location-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;

    .main-content {
      max-height: calc(100vh - 360px);
      overflow: auto;
      max-width: 1280px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: @mobile-size) {
        min-width: 1280px;
      }
    }

    .main-row-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 1280px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 0.75rem 16px;

      &.is-dragging {
        background-color: @custom-selected-color;
        opacity: 0.7;
      }

      &.is-header {
        padding-bottom: 1rem;
        font-size: 0.875rem;
        color: #1A1A1C;
        font-weight: 600;
        text-align: center;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: transparent;

        .index-field {
          height: auto;
        }
      }

      &.is-submit-button-container {
        padding-left: 0;
        margin-top: 1rem;
        justify-content: flex-start;

        .add-new-button {
          width: 100px;
        }
      }

      .index-field {
        width: 100px;
        min-width: 60px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          color: #333333;
          border-radius: 50%;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
        }
      }

      .name-field {
        width: 100%;
        min-width: 200px;
        max-width: 400px;

        .p-inputtext {
          width: 100%;
        }
      }

      .meraki-field {
        margin-left: 3rem;
        width: 100%;
        min-width: 200px;
        max-width: 400px;

        .p-dropdown {
          width: 100%;
        }
      }

      .action-field {
        width: 60px;
        min-width: 60px;
      }

      .submit-button {
        margin-left: 0;
        margin-right: 1rem;
        width: 100px;
      }

      .add-button {
        width: 100px;
      }

      .input-text-field-container {
        position: relative;

        .p-inputtext {
          width: 100%;
        }

        .validation-error-message {
          font-size: 0.75rem;
          position: absolute;
          bottom: -1.1rem;
          left: 0.5rem;
          color: @custom-red;
        }

        &.meraki-field {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;

          .meraki-field-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 1rem;
            position: relative;

            .p-dropdown-label.p-inputtext {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}