@import '@/styles/_variables.less';

.network-container {
  &.air-quality-now {
    width: 100%;
    padding: 0 !important;
    padding-top: 1rem !important;

    .text-left {
      display: flex;
      align-items: center;
      justify-content: space-between !important;
      @media (max-width: @mobile-size) {
        flex-wrap: wrap;
      }

      .title-text {
        font-weight: bold;
        font-size: 1.25rem;
      }
    }

    .network-content {
      padding-top: 0 !important;

      .map-table-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;

        .sensor-type-select-button {
          width: fit-content;
          z-index: 100;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 1rem;

          @media (max-width: 850px) {
            order: 2;
          }
        }

        .map-container {
          width: calc(100% - 700px);
          padding: 0.5rem;
          height: auto;

          @media (max-width: 1200px) {
            width: 50%;
          }

          @media (max-width: 850px) {
            width: 100%;
            padding: 0;
            order: 3;
          }

          .image-marker-tools {
            z-index: 101;
            margin-top: 0;
          }

          .custom-marker {
            @media (max-width: @mobile-size) {
              @mobile-width: 42px;
              width: @mobile-width !important;
              height: @mobile-width !important;
            }
          }

          .image-marker {
            img {
              height: auto;
              width: 100%;
            }
          }
        }

        .table-container {
          width: 700px;
          max-width: 700px;
          min-width: 700px;
          margin-top: 0.5rem;

          @media (max-width: 1200px) {
            width: 50%;
            min-width: auto;
          }

          @media (max-width: 850px) {
            width: 100%;
            max-width: auto;
            padding: 0;
            margin-top: 8px;
            margin-bottom: 32px;
            order: 1;
          }

          .sensor-data-table {
            .p-datatable-table {
              margin-left: auto;
              margin-right: auto;

              td {
                padding: 0;
                font-size: 1rem;

                .column-color {
                  padding: 0.25rem 0.5rem;
                  background-color: white;
                }
              }

              th {
                padding: 0.25rem;
                // font-size: 0.875rem;

                .p-sortable-column-icon {
                  width: 0.85rem;
                  height: 0.85rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
