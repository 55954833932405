@import '@/styles/_variables.less';

.network-container {
  &.meeting-room-outlook-unused-booking {
    padding: 1rem;
    align-items: flex-start;

    .network-tool-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .data-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      .data-container__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0;
        padding: 0.5rem;

        @table-width: 390px;

        &.chart-type {
          width: calc(100% - @table-width);
        }

        &.table-type {
          width: @table-width;
          max-width: @table-width;
        }

        .chart-container {
          margin-top: 0.5rem;
          width: 100%;

          .recharts-responsive-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .datatable-container {
          width: 100%;
          margin-top: 1rem;
        }
      }
    }
  }
}