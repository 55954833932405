.datepicker {
  line-height: 36px;
  margin: 5px 5px 0px 5px;
  font-weight: bold;
}
.datepicker .datepicker-close {
  background: #fff;
  cursor: pointer;
  border: 1px solid #ccc;
  box-shadow: none !important;
  border-radius: 5px;
  height: 36px;
  font-size: 13px;
  color: #333F50;
  padding: 0 11px;
  letter-spacing: -0.5px;
}
.datepicker .datepicker-close .datepicker-close-separator {
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  color: #00AFED;
  text-align: center;
  display: inline-block;
  padding-left: 2px;
}
.datepicker .datepicker-close .datepicker-close-separator:before {
  display: block;
  width: 100%;
  height: 100%;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\F054";
}
.datepicker-open {
  z-index: 1000 !important;
  margin: 0;
  margin-top: -5px;
  border-radius: 4px;
  padding: 4px;
  background: #fff;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
}
.datepicker-open .rdr-Day.is-inRange {
  background: #ced4da !important;
}
.datepicker-open .rdr-Day.is-selected {
  background: #00AFED !important;
}
.datepicker-open .rdr-PredefinedRangesItem {
  border-radius: 4px !important;
  font-weight: 500;
}
.datepicker-open .rdr-PredefinedRangesItem.rdr-PredefinedRangesItemActive {
  background: #00AFED !important;
  color: #fff !important;
}
.datepicker-open .rdr-MonthAndYear-innerWrapper {
  font-weight: 500;
}
.datepicker-open .rdr-MonthAndYear-button {
  cursor: pointer;
  background: #00AFED !important;
}
.datepicker-open .rdr-MonthAndYear-button.next i {
  border-color: transparent transparent transparent #fff !important;
}
.datepicker-open .rdr-MonthAndYear-button.prev i {
  border-color: transparent #fff transparent transparent !important;
}
.selectbox__control {
  width: calc(180px - 10px);
  margin: 5px 5px 0px 5px;
  font-weight: bold;
}
.sidebar .selectbox__control {
  width: calc(205px - 1.7rem);
  margin: 0;
  font-weight: bold;
}
.selectbox__control:hover {
  cursor: pointer;
}
.selectbox__menu {
  z-index: 5;
}
* {
  box-sizing: border-box;
  font-family: 'Noto Sans JP', 'Noto Serif', sans-serif, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 10px 0 5px 0;
}
h1 {
  padding: 0 10px;
  font-size: 40px;
  font-weight: 500;
}
h2 {
  font-size: 32px;
  font-weight: 500;
  color: #343a40;
}
h3 {
  font-size: 28px;
  font-weight: 500;
  color: #343a40;
}
h4 {
  font-size: 24px;
  font-weight: 500;
  color: #343a40;
}
h5 {
  font-size: 20px;
  font-weight: 500;
  color: #343a40;
}
h6 {
  font-size: 16px;
  font-weight: 500;
  color: #343a40;
}
p {
  font-size: 11px;
  font-weight: 400;
}
blockquote {
  font-size: 16.8px;
  font-weight: 400;
}
.no-margin {
  margin: 0;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.graph-title {
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
  font-weight: 200;
  color: #8497b0;
  -webkit-font-smoothing: antialiased;
}
.menu {
  z-index: 10;
  background: linear-gradient(90deg, #232348 0%, #5555ae 100%);
  min-height: 99vh;
  max-height: 100vh;
}
.menu ul,
.menu li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu .link-text {
  margin-right: 10px;
  display: block;
}
.menu .link-text a.nav-link {
  display: block;
  padding: 13px 0 13px 64px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 45px;
  position: relative;
  top: 50%;
  transform: translateY(25%);
}
.menu .link-text a.nav-link.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.menu .fp-link {
  text-decoration: none;
  color: grey;
  font-family: "Source Sans Pro";
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 23px;
  text-align: left;
  cursor: pointer;
  color: #B0B0B0;
}
.menu .fp-link .sidebar-container {
  margin-top: 0.5rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-transform: uppercase;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.menu .fp-link .sidebar-container span:hover {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}
.menu .fp-link.active .sidebar-container {
  background-color: rgba(255, 255, 255, 0.2);
}
.menu .fp-link.active .sidebar-container span:hover {
  color: #00CCF9;
}
.menu .fp-link.active .link-text {
  color: #00CCF9;
}
.menu .fp-link.disabled .link-text {
  color: grey;
}
.menu .fp-link div {
  height: 100%;
}
.menu .fp-link div .link-text {
  display: block;
  height: 100%;
  padding: 15px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  margin: 0;
}
.menu .fp-link div i {
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  font-size: 18px;
  height: 28px;
  width: 28px;
  text-align: center;
  line-height: 28px;
  position: absolute;
  color: white;
}
.menu .fp-link .fp-export {
  cursor: pointer;
}
.menu .fp-link.hidden-router-link .sidebar-container {
  box-shadow: none;
  background-color: transparent;
  color: transparent;
}
html {
  scrollbar-width: none;
}
body {
  background: #fff;
  -ms-overflow-style: none;
}
body .login-logo {
  opacity: 0;
}
body .fp-client-authority-manager.signout .header-login .dots {
  opacity: 0;
}
@media (max-width: 768px) {
  body .fp-client-authority-manager-container .fp-client-authority-manager {
    padding: 2rem;
  }
}
body .fp-client-authority-manager-container .fp-client-authority-manager .standard-login .login-screen .forgot-password-link-container .forgot-password-link:hover {
  color: #333333 !important;
  font-weight: 500;
}
body .fp-client-authority-manager-container .fp-client-authority-manager .standard-login .login-screen .return-button:hover {
  color: #333333 !important;
  font-weight: 500;
}
body::-webkit-scrollbar {
  display: none;
}
body .fp-logout button.btn.reconnect-button {
  width: auto;
}
.fp-loader-spinner .fp-loader-spinner_bar {
  background-color: grey;
}
.root-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #232348 0%, #5555ae 100%);
}
.root-container.not-logged {
  background: #3E4550;
}
.no-scroll .grid-layout-inner {
  overflow: hidden !important;
}
.footer {
  text-align: center;
  font-size: 11px;
}
.footer a {
  color: #888;
  text-decoration: none;
}
.footer img {
  height: 15px;
  padding-left: 3px;
}
.page {
  z-index: 1;
  position: absolute;
  left: 205px;
  bottom: 0;
  top: 59px;
  right: 0;
  background: linear-gradient(90deg, #232348 0%, #5555ae 100%);
  transition: left 0.3s ease-in-out;
}
.page .fp-dashboard {
  width: 100%;
  height: auto;
}
.page .fp-dashboard .grid-layout {
  overflow: auto;
  padding: 1rem;
}
.page .fp-dashboard .grid-layout .grid-layout-inner {
  overflow: visible !important;
}
.page .fp-dashboard .grid-layout .grid-layout-inner > div {
  position: unset !important;
  width: 100%;
  height: 100% !important;
}
.page.page--sidebar-hidden {
  left: 0;
}
.page.page-mobile {
  left: 0;
}
.page.page-mobile .fp-dashboard .grid-layout {
  padding: 0.5rem;
}
.fp-core-ui-panel {
  height: 100%;
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 10px;
  overflow: auto;
}
.fp-core-ui-panel .grid-layout {
  height: 100%;
  padding: 15px;
}
.fp-core-ui-panel .grid-layout.highlight {
  background: #6c3d8d;
  background: -moz-linear-gradient(-45deg, #19393f 0%, #224b6b 30%, #01b0ee 100%);
  background: -webkit-linear-gradient(-45deg, #19393f 0%, #224b6b 30%, #01b0ee 100%);
  background: linear-gradient(135deg, #19393f 0%, #224b6b 30%, #01b0ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6c3d8d', endColorstr='#01b0ee', GradientType=1);
  color: #fff;
}
.fp-core-ui-panel .grid-layout.highlight .chart-html > div {
  background: transparent;
}
.fp-core-ui-panel .grid-layout.highlight .card-title .subtitle {
  color: #9dc3e6;
}
.fp-core-ui-panel .grid-layout.highlight .chart-html i {
  color: #99c1e2;
}
.fp-core-ui-panel .grid-layout.highlight .value {
  color: #fff;
}
.fp-core-ui-panel .grid-layout.highlight .sub-value {
  color: #99c1e2;
}
.fp-chart,
.fp-chart-content {
  height: 100%;
}
.fp-error-query {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}
.fp-error-query .refresh {
  display: block;
  cursor: pointer;
}
.fp-error-query .refresh i {
  display: block;
  font-size: 30px;
}
.menu {
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  position: relative;
  background: linear-gradient(90deg, #232348 20%, #5555ae 100%);
  height: 63px;
  padding: 0px 0px;
}
.header.header-mobile {
  background-color: linear-gradient(90deg, #232348 20%, #5555ae 100%);
}
.header.header--sidebar-hidden {
  background-color: linear-gradient(90deg, #232348 20%, #5555ae 100%);
}
.header .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.header .container .fp-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.header .container .fp-container .container-inner {
  display: flex;
}
.header .container .fp-container .container-inner .menu-lists {
  display: flex;
}
.header .container .fp-container.user {
  display: flex;
  justify-content: flex-end;
}
.header .container .fp-container.user .container-inner {
  margin-right: 30px;
}
.header .container .fp-container.hideshowsidebar {
  transition: margin-left 0.3s ease-in-out;
  margin-left: calc(205px + 10px);
  width: auto;
}
.header .container .fp-container.hideshowsidebar .toggle-button-container {
  display: none;
}
.header .container .fp-container.hideshowsidebar.hideshowsidebar-sidebar-hidden {
  transform: translateX(0);
  margin-left: 0px;
}
.header .container .fp-container.hideshowsidebar.hideshowsidebar-sidebar-hidden .toggle-button-container {
  display: flex;
}
.header .container .fp-container.hideshowsidebar.hideshowsidebar-sidebar-hidden .toggle-button-container .p-button {
  color: white;
}
.logo-container ul {
  display: flex;
  justify-content: center;
}
.logo .fp-image {
  height: 24px;
  width: auto;
}
.header .container .fp-container.tabs {
  align-items: flex-end;
}
.header .container .fp-container.tabs .fp-link {
  color: grey;
  font-family: "Source Sans Pro";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}
.header .container .fp-container.tabs .fp-link div {
  padding: 30px 40px 3px 40px;
  display: flex;
  align-items: flex-end;
  height: unset;
}
.header .container .fp-container.tabs .fp-link div i {
  color: grey;
  position: relative;
  font-size: 18px;
  line-height: 23px;
  height: auto;
  width: auto;
}
.header .container .fp-container.tabs .fp-link div .link-text {
  padding: 0px 5px;
  font-weight: 500;
}
.header .container .fp-container.tabs .fp-link.active div {
  border-bottom: 2px solid #00CCF9;
}
.header .container .fp-container.tabs .fp-link.active .link-text {
  color: #00CCF9;
}
.header .container .fp-container.tabs .fp-link.active i {
  color: #00CCF9;
}
.sidebar {
  display: flex;
  width: fit-content;
  background: linear-gradient(90deg, #232348 0%, #5555ae 100%);
  flex: 1 1 auto;
  height: calc(100vh + 59px);
  margin-top: -59px;
  overflow: hidden;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
  z-index: 2;
}
.sidebar:hover {
  overflow-y: auto;
}
.sidebar.sidebar--hidden {
  transform: translateX(-100%);
}
.sidebar .container {
  display: flex;
  flex-direction: column;
  background: linear-gradient(90deg, #1d1d2d 0%, #232348 100%);
  color: white;
  height: 100%;
}
.sidebar .container .container-inner {
  width: 205px;
  padding: 5px;
  background: linear-gradient(90deg, #1d1d2d 0%, #232348 100%);
}
.sidebar .container .fp-container {
  display: flex;
}
.sidebar .container .fp-container.footer-component {
  margin-top: auto;
}
.sidebar.sidebar-mobile {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 100;
  border-right: 1px solid #dddddd;
}
.footer {
  flex: 1;
  align-items: flex-end;
  flex: 1 1 auto;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}
.dyn-title {
  padding-top: 5px;
  padding-left: 5px;
  color: #3E4550;
  font-family: "Source Sans Pro";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.58px;
  line-height: 19px;
}
.chart-title {
  color: #3E4550;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}
.panel-title {
  color: #3E4550;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}
.panel-description {
  color: #3E4550;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}
.fp-tabs {
  position: absolute;
  overflow: visible;
}
.fp-tabs .fp-tabs-content {
  position: absolute;
  height: calc(100% - 30px);
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  overflow: auto;
  top: 30px;
  left: 0px;
  right: 0px;
  bottom: 15px;
}
.fp-tabs .tabs-header {
  position: relative;
  z-index: 100;
}
.fp-tabs .fp-tabs-heads {
  background: transparent;
  position: absolute;
  padding: 0;
  margin: 0px 3px;
  border-top: 2px solid #f2f2f2;
  border-right: 2px solid #f2f2f2;
  z-index: 100;
  font-weight: 900;
  font-size: 14px;
  text-transform: uppercase;
}
.fp-tabs .fp-tabs-heads .fp-tabs-head {
  display: inline-block;
  list-style: none;
  color: white;
  cursor: pointer;
  height: 28px;
  line-height: 16px;
  padding: 4px 20px 0px 20px;
  margin: 0px 0px 0px -1px;
  background-color: #fff;
  text-align: center;
}
.fp-tabs .fp-tabs-heads .fp-tabs-head span,
.fp-tabs .fp-tabs-heads .fp-tabs-head .fp-tabs-head-editor {
  margin-bottom: 5px;
  bottom: 1px;
  color: grey;
  font-weight: 400;
  text-transform: uppercase;
}
.fp-tabs .fp-tabs-heads .fp-tabs-head span input,
.fp-tabs .fp-tabs-heads .fp-tabs-head .fp-tabs-head-editor input {
  padding: 0;
}
.fp-tabs .fp-tabs-heads .fp-tabs-head:hover {
  background-color: #f2f2f2;
}
.fp-tabs .fp-tabs-heads .fp-tabs-head:hover span,
.fp-tabs .fp-tabs-heads .fp-tabs-head:hover .fp-tabs-head-editor {
  opacity: 0.7;
  color: black;
}
.fp-tabs .fp-tabs-heads .fp-tabs-head.active {
  border-bottom: 2px solid #00CCF9;
}
.fp-tabs .fp-tabs-heads .fp-tabs-head.active span,
.fp-tabs .fp-tabs-heads .fp-tabs-head.active .fp-tabs-head-editor {
  opacity: 1;
  color: #00CCF9;
}
.recharts-legend-wrapper {
  overflow: scroll;
}
.config-container {
  background-color: #ffffff;
  box-shadow: 4px 4px 4px 0px #00000040;
  border-radius: 1.5rem;
}
@media (max-width: 811px) {
  .config-container {
    border-radius: 4px;
  }
}
.config-container.pc-mode-enabled {
  min-width: 1280px;
}
.config-container .multiselect-style {
  max-width: 616px;
  width: 100%;
  min-width: 220px;
}
@media (max-width: 811px) {
  .config-container .multiselect-style {
    max-width: 100%;
    margin-top: 8px;
  }
}
.config-container .performance-config-title-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.config-container .performance-config-title-container .title-container {
  width: 20%;
}
@media (max-width: 850px) {
  .config-container .performance-config-title-container .title-container {
    width: 100%;
  }
}
.config-container .performance-config-title-container .performance-config-container {
  width: 80%;
}
@media (max-width: 850px) {
  .config-container .performance-config-title-container .performance-config-container {
    margin-top: 16px;
    width: 100%;
  }
}
.config-container .performance-config-title-container .performance-config-container .upper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.config-container .performance-config-title-container .performance-config-container .lower {
  width: 100%;
}
.config-container .performance-config-title-container .performance-config-container .lower .multiselect-container {
  display: flex;
  justify-content: flex-end;
}
.config-container .config-content {
  width: 100%;
  height: auto;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 4px;
}
.config-container .config-content .title-chart-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  width: 100%;
}
.config-container .config-content .title-chart-container .title-chart {
  font-weight: bold;
}
.config-container .config-content .title-chart-container .title-chart__right .p-multiselect-label {
  text-align: left;
}
.config-container .config-content .network-container {
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}
@media (max-width: 811px) {
  .config-container .config-content .network-container {
    padding: 0;
  }
}
.config-container .config-content .network-container .network-title-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  background-color: #dcdcdc;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.75rem 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
}
@media (max-width: 811px) {
  .config-container .config-content .network-container .network-title-container {
    text-align: left;
  }
}
.config-container .config-content .network-container .network-content {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.config-container.custom-config-container {
  max-width: 100%;
  min-height: calc(100vh - 100px);
  height: calc(100vh - 100px);
  padding: 1.5rem 1rem;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 811px) {
  .config-container.custom-config-container {
    padding: 0.5rem;
  }
}
.config-container.custom-config-container .config-title-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: auto;
  margin-bottom: 0.5rem;
}
.config-container.custom-config-container .config-title-container .title-text {
  font-size: 1.25rem;
  font-weight: 500;
}
.config-container.custom-config-container[class$='-setting-container'] .p-dropdown {
  background-color: white;
}
.config-container.custom-config-container[class$='-setting-container'] .p-multiselect {
  background-color: white;
}
.config-container.custom-config-container[class$='signage-components-container'] .p-dropdown {
  background-color: white;
}
.config-container.custom-config-container[class$='signage-components-container'] .p-multiselect {
  background-color: white;
}
.config-container .image-marker {
  margin: 0.25rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  /* or block if you want it to be a block element */
  position: relative;
  /* optional */
}
.config-container .image-marker .image-marker__image.custom-image-marker {
  width: auto;
}
.config-container .image-marker .custom-marker {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #555555;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 850px) {
  .config-container .image-marker .custom-marker {
    width: calc(100vw / 24) !important;
    height: calc(100vw / 24) !important;
  }
}
.config-container .image-marker .custom-marker .text-count {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.25rem;
}
@media (max-width: 850px) {
  .config-container .image-marker .custom-marker .text-count {
    font-size: 1rem !important;
  }
}
.config-container .image-marker .custom-marker .face-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #5f8d4e;
}
.config-container .image-marker .custom-marker .face-image img {
  display: block;
  max-width: 100%;
  height: auto;
}
.config-container .image-marker .custom-marker.device-status-marker.device-status-marker-icon {
  border-radius: 0;
  border: 0;
}
.config-container .image-marker .custom-marker.device-status-marker.device-status-marker-icon .face-image {
  border-radius: 0;
  border: 0;
  background-color: transparent;
  width: 40px;
  height: 40px;
}
.config-container .image-marker .custom-marker.device-status-marker.device-status-marker-icon .face-image img {
  object-fit: contain;
}
.p-tooltip.p-component.p-tooltip-active .p-tooltip-text {
  background-color: #f5f5f5;
  border: 2px solid #555555;
  box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);
  color: #444444;
}
.p-tooltip.custom-marker-tooltip {
  border-radius: 5px;
  min-width: 280px;
  max-width: 480px;
}
.p-tooltip.custom-marker-tooltip .p-tooltip-text {
  background-color: #f5f5f5;
  border: 2px solid #555555;
  box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);
  color: #444444;
}
.p-tooltip.custom-marker-tooltip .p-tooltip-text .display-marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.p-tooltip.custom-marker-tooltip .p-tooltip-text .display-marker .display-marker__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  padding-bottom: 0.125rem;
  padding-top: 0.125rem;
}
.p-tooltip.custom-marker-tooltip .p-tooltip-text .display-marker .display-marker__container .text-label {
  text-align: left;
  white-space: nowrap;
}
.p-tooltip.custom-marker-tooltip .p-tooltip-text .display-marker .display-marker__container .text-value {
  text-align: right;
  padding-left: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.chart-container {
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 4px;
}
.p-component.p-inputtext {
  padding: 0.25rem 0.75rem;
  height: 40px;
  font-size: 0.9rem;
  border: 2px solid #0000005c;
  border-radius: 4px;
}
.p-component.p-inputtext.p-invalid {
  border-width: 2px;
  border-color: #F44336;
}
.p-component.p-button {
  height: 40px;
  border-radius: 4px;
}
@media (max-width: 811px) {
  .p-component.p-button {
    height: 36px;
  }
}
.p-component.p-button.refresh-button {
  background-color: #174fe3;
  border-color: #174fe3;
  width: 88px;
}
.p-component.p-button.submit-button {
  background-color: #174fe3;
  border-color: #174fe3;
}
.p-component.p-button.reset-button {
  background-color: #818181;
  border: 0;
}
.p-component.p-button.back-button {
  background-color: #818181;
  width: 88px;
  border: 0;
}
.p-component.p-button.add-button {
  background-color: #818181;
  border: 0;
}
.p-component.p-button.p-button-info.has-shadow {
  box-shadow: 4px 4px 4px 0px #527dee;
}
.p-component.p-button.p-button-secondary.has-shadow {
  box-shadow: 4px 4px 4px 0px #aeaeae;
}
.p-component.p-button.p-button-success.has-shadow {
  box-shadow: 4px 4px 4px 0px #4ddb81;
}
.p-component.p-selectbutton.p-button-group {
  border-radius: 4px;
  box-shadow: 4px 4px 4px 0px #aeaeae;
}
.p-component.p-selectbutton.p-button-group .p-button {
  border-radius: 0;
}
.p-component.p-selectbutton.p-button-group .p-button:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-component.p-selectbutton.p-button-group .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-component.p-dropdown {
  border: 2px solid #0000005c;
  border-radius: 4px;
  background: transparent;
}
.p-component.p-inputwrapper {
  height: 40px;
}
.p-component.p-inputwrapper.p-invalid .p-inputtext {
  border-width: 2px;
  border-color: #F44336;
}
.p-component.p-inputwrapper .p-multiselect-label-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-component.p-inputwrapper .p-multiselect-label {
  font-size: 0.875rem;
  padding: 0;
}
.p-component.p-inputwrapper .p-dropdown-label {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-component.p-inputwrapper .p-inputtext {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-component.p-inputwrapper .p-datepicker-trigger {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-component.p-confirm-dialog {
  min-width: 320px;
}
.p-component.p-confirm-dialog .p-confirm-dialog-message {
  width: 100%;
}
.p-component.p-confirm-dialog .p-dialog-content {
  min-height: 80px;
}
.p-component.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 2px #00B2FF;
}
.p-component.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #0070ff;
}
.p-component.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #00B2FF;
}
.p-component.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-component.p-checkbox .p-checkbox-box {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}
.p-component.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-weight: 700;
}
.p-component.p-multiselect {
  border: 0;
  border-bottom: 1px solid #dddddd;
  border-radius: 0;
  height: 49px;
  background-color: transparent;
}
.p-component.p-multiselect-panel .multi-select-item-template {
  display: flex;
  align-items: center;
}
.p-component.p-slider .p-slider-range {
  background: #00B2FF;
}
.p-component.p-slider .p-slider-handle {
  border-color: #00B2FF;
}
.p-component.p-slider .p-slider-handle:not(.p-disabled):hover {
  border-color: #00B2FF;
  background: #00B2FF;
}
.p-component.p-slider .p-slider-handle:focus {
  box-shadow: 0 0 0 1px #00B2FF;
}
.p-component.p-toast {
  opacity: 1;
}
.p-component .p-toast-message .p-toast-message-content {
  color: white;
  border-radius: 8px;
  padding: 1rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.p-component .p-toast-message .p-toast-message-content .p-toast-message-icon {
  color: white;
  width: 1.5rem !important;
  font-size: 1.5rem !important;
}
.p-component .p-toast-message .p-toast-message-content .p-toast-icon-close-icon {
  color: white;
}
.p-component .p-toast-message .p-toast-message-content .p-toast-message-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.p-component .p-toast-message .p-toast-message-content .p-toast-message-text .p-toast-detail {
  text-align: left;
}
.p-component .p-toast-message.p-toast-message-error {
  background: #F44336;
}
.p-component .p-toast-message.p-toast-message-error .p-toast-message-content {
  background: #F44336;
}
.p-component .p-toast-message.p-toast-message-success {
  background: #80C535;
}
.p-component .p-toast-message.p-toast-message-success .p-toast-message-content {
  background: #80C535;
}
.p-component .p-toast-message.p-toast-message-info * {
  color: #2563eb;
}
.p-component.p-sidebar {
  width: 100%;
  max-width: 500px;
  min-width: 320px;
  background-color: #eeeeee;
}
@media (max-width: 811px) {
  .p-component.p-sidebar {
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
}
.p-component.p-sidebar .p-sidebar-header {
  justify-content: flex-start;
}
@media (max-width: 811px) {
  .p-component.p-sidebar .p-sidebar-header {
    padding: 0.85rem;
  }
}
.p-component.p-sidebar .p-sidebar-header .p-sidebar-close {
  border: 1px solid #dddddd;
  border-radius: 50%;
  background-color: #fff;
}
@media (max-width: 811px) {
  .p-component.p-sidebar .p-sidebar-content {
    padding: 0.85rem;
  }
}
.p-component .p-dialog-footer {
  padding-top: 1.5rem;
}
@media (max-width: 811px) {
  .p-sidebar-mask.p-component-overlay {
    padding: 0.75rem;
  }
}
.spinner-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.no-data-container {
  margin-top: 0.5rem;
  background-color: #F44336;
  color: #ffffff;
  font-weight: 600;
  max-width: 160px;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.image-marker-tools.p-buttonset .p-button {
  border-radius: 0;
}
.image-marker-tools.p-buttonset .p-button:hover {
  background-color: #00B2FF;
  color: #fff;
  border-color: #00B2FF;
  font-weight: bold;
}
.image-marker-tools.p-buttonset .p-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.image-marker-tools.p-buttonset .p-button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
