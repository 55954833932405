@import '@/styles/_variables.less';

.network-container {
  &.meeting-room-outlook-table {
    margin-bottom: 0 !important;

    .network-content {
      padding-bottom: 0 !important;
    }

    .table-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #444444;

      .outlook-data-table {
        width: 100%;

        .p-datatable-table {
          .p-datatable-thead {
            z-index: 22;

            th {
              font-weight: bold;
              color: #333333;

              &.room-name {}

              &.red-title {
                background-color: red;
                color: #ffffff;

                .p-icon {
                  color: #ffffff;
                }
              }

              &.gray-title {
                background-color: rgb(161, 160, 160);
                color: #ffffff;

                .p-icon {
                  color: #ffffff;
                }
              }

              &.yellow-title {
                background-color: #cea000;
                color: #ffffff;

                .p-icon {
                  color: #ffffff;
                }
              }
            }
          }

          .p-datatable-tbody {
            td {
              color: #333333;
              font-size: 1.125rem;

              &.has-percentage {
                padding: 0;
                position: relative;

                >div {
                  padding: 1rem 1.5rem;
                  z-index: 21;
                  background-color: transparent;
                  position: relative;
                }

                >.percentage-bg {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  height: 100%;
                  z-index: 20;
                  padding: 0;
                  background-color: rgba(189, 211, 254, 0.7);
                }
              }
            }
          }
        }
      }
    }
  }
}