@import '@/styles/_variables.less';

.p-component-overlay {
  .p-dialog.signage-play-dialog {
    .p-dialog-content {
      padding: 0;

      .signage-play-container {
        height: 100vh;

        .flicking-arrow-prev {
          display: none;
        }

        .flicking-arrow-next {
          display: none;
        }

        .panel {
          width: 100%;
        }

        .card {
          position: relative;
          height: 100%;

          .flicking-camera {
            height: 75vh;
          }

          .spinner-container {
            position: absolute;
            width: 150px;
            height: 150px;
            top: calc(50% - 75px);
            left: calc(50% - 75px);
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
          }

          .control-button-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;
            opacity: 0.5;
            position: fixed;
            left: 2rem;
            right: 0;
            bottom: 0;
            bottom: 1.25rem;
            z-index: 10;

            &:hover {
              opacity: 1;
            }

            .control-button {
              height: 54px;
              width: 54px;
              border: 2px solid @border-color;
              border-radius: 50%;
              font-weight: bold;

              .p-button-icon {
                color: @border-color;
              }

              &:hover {
                background-color: rgb(23, 79, 227);
                border: 2px solid rgb(23, 79, 227);
                color: white;

                .p-button-icon {
                  color: @border-color;
                }
              }

              &.control-play-btn {
              }

              &.control-back-btn {
              }

              &.control-next-btn {
              }
            }
          }

          .notice {
            padding: 1.5rem 1rem;

            .notice-content {
              display: flex;
              justify-content: flex-start;
              flex-direction: column;
              width: 100%;
              font-size: 1rem;
              color: rgba(0, 0, 0, 0.87);
              border-radius: 24px;
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              background: linear-gradient(
                171.97deg,
                #f7f7f7 6.55%,
                #f9f9f9 94.2%
              );
              padding-bottom: 20px;

              .title-text {
                font-weight: bold;
                padding-top: 10px;
                padding-left: 36px;
              }

              .config-content {
                white-space: pre-wrap;
                padding-left: 36px;
              }
            }
          }
        }

        .config-container {
          border: 0;
          box-shadow: none;
          width: 100%;
          height: 100%;

          .config-title-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 1rem;
            font-weight: bold;
            background-color: #dcdcdc;
            color: rgba(0, 0, 0, 0.87);
            padding: 0.5rem 1.5rem;
            border-radius: 0.25rem;
            box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
            height: 40px;

            @media (max-width: @mobile-size) {
              height: auto;
              flex-wrap: wrap;
            }

            .custom-current-container {
              text-align: end;
              @media (max-width: @mobile-size) {
                text-align: start;
              }
            }

            .custom-current-time {
              text-align: end;
              @media (max-width: @mobile-size) {
                text-align: start;
              }
            }
          }

          .config-content {
            .image-container {
              box-shadow: 4px 4px 4px 0px #00000040;
              margin-right: 1rem;
              margin-bottom: 1rem;
              border-radius: @custom-border-radius;

              .image-marker-tools {
                display: none;
              }
            }
          }

          &.meeting-room-usage {
            .config-content {
              .image-container {
                border: 1px solid @border-color;
                width: 100%;
                max-width: 45%;
              }

              .table-container {
                width: 100%;
                max-width: 55%;
                margin-right: 0.5rem;
                margin-bottom: 0.5rem;
                box-shadow: 4px 4px 4px 0px #00000040;
                border-radius: @custom-border-radius;
                border: 1px solid @border-color;
              }
            }
          }

          &.people-counts {
            .config-content {
              .image-container {
                margin-left: auto;
                margin-right: auto;
                box-shadow: none;
                border-radius: 0;

                .custom-image-marker {
                  height: 65vh;
                  box-shadow: 4px 4px 4px 0px #00000040;
                  border-radius: @custom-border-radius;
                  border: 1px solid @border-color;
                }
              }
            }
          }
        }

        .network-container.air-quality-now {
          max-width: 100%;
          min-height: calc(75vh - 100px);
          height: calc(75vh - 100px);
          padding: 1.5rem 1rem !important;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;

          .network-content {
            height: 100%;
            width: 100%;
          }

          .text-left {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            font-size: 1rem;
            font-weight: bold;
            background-color: #dcdcdc;
            color: rgba(0, 0, 0, 0.87);
            padding: 0.5rem 1.5rem;
            border-radius: 0.25rem;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
          }

          .map-table-container {
            flex-wrap: nowrap;
            justify-content: space-between;
            height: 100%;
          }

          .map-container {
            box-shadow: 4px 4px 4px 0px #00000040;
            border-radius: @custom-border-radius;
            border: 1px solid @border-color;
            margin-top: 1rem;
            margin-right: 1rem;
            flex: 1;
            width: 100%;
            max-width: 60%;
            height: 65vh;

            .react-transform-wrapper {
              height: 100%;

              .react-transform-component {
                height: 100%;
              }
            }

            .image-marker {
              height: 100%;

              .image-marker__image.custom-image-marker {
                &.image-marker__image--landscape {
                  height: 100%;
                  // width: auto;
                }

                &.image-marker__image--portrait {
                  // height: auto;
                  width: 100%;
                }
              }
            }

            .image-marker-tools {
              display: none;
            }

            .custom-marker {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              border: 1px solid #555555;
              display: flex;
              align-items: center;
              justify-content: center;

              @media (max-width: 850px) {
                @mobile-width: calc(100vw / 24);
                width: @mobile-width !important;
                height: @mobile-width !important;
              }

              .text-count {
                font-size: 2rem;
                font-weight: bold;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 0.25rem;

                @media (max-width: 850px) {
                  font-size: 1rem !important;
                }
              }

              .face-image {
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 2px solid #5f8d4e;

                img {
                  display: block;
                  max-width: 100%;
                  height: auto;
                }
              }

              &.device-status-marker {
                &.device-status-marker-icon {
                  border-radius: 0;
                  border: 0;

                  .face-image {
                    border-radius: 0;
                    border: 0;
                    background-color: transparent;
                    width: 40px;
                    height: 40px;

                    img {
                      object-fit: contain;
                    }
                  }
                }
              }
            }
          }

          .table-container {
            box-shadow: 4px 4px 4px 0px #00000040;
            border: 1px solid @border-color;
            border-radius: @custom-border-radius;
            margin-top: 1rem;
            margin-bottom: 1rem;
            flex: 1;
            width: 100%;
            max-width: 40%;
          }
        }
      }
    }
  }
}
