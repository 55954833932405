@import '@/styles/_variables.less';

.config-container {
  &.summary-page {
    align-items: flex-start;

    .config-content {
      justify-content: flex-start;
    }

    .map-container {
      display: inline-block;
      width: 100%;
    }
  }
}