@import '@/styles/_variables.less';

.meraki-event-log {
  .filter-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1280px;
    width: 100%;
    align-items: flex-start;

    .left,
    .right {
      width: 50%;

      .custom-calendar {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        flex-direction: column;
        background-color: transparent;

        .p-calendar.custom-date-picker {
          border: 0;

          .p-datepicker-trigger {
            display: none;
          }

          &.p-inputwrapper {
            height: 32px;
            width: 120px;
            border: 0;
            border-bottom: 1px solid #dddddd;
            border-radius: 0;

            .p-inputtext {
              box-shadow: none;
              justify-content: flex-start;
              padding: 0;
              border: 0;
              font-weight: 700;
              border-radius: 0;
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
      }

      .input-container {
        width: 100%;
        padding: 8px 15px;
        display: flex;
        align-items: center;

        .label-text {
          width: 35%;
          text-align: left;
          font-weight: bold;
        }

        .input-style {
          width: 65%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 2rem;

          .custom-calendar {
            width: auto;

            .custom-date-picker {
              width: 100%;
              border-bottom-width: 2px;
            }

            &.date-calendar {
              .p-inputtext {
                width: 150px;
              }
            }

            &.time-picker {
              .p-inputtext {
                width: 60px;
              }
            }

            .p-inputtext {
              font-size: 1rem;
              text-align: center;
            }
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-start;

      .button-style {
        margin-top: 12px;
        margin-left: 36%;
      }
    }
  }
}