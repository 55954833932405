
.toastify-content {
  box-shadow: 0 8px 10px 0 rgba(62,69,80,0.29);
  border-radius: 10px;
  opacity: 0.9;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.08px;
  padding: 15px;

  &.toastify-content--error {
    background: #FF6F90;
    box-shadow: 0 8px 10px 0 rgba(62,69,80,0.29);
    border-radius: 10px;
    opacity: 0.9;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.08px;
    padding: 15px;
  }

  &.toastify-content--success {
    background: #3FE5CA;
    box-shadow: 0 8px 10px 0 rgba(62,69,80,0.29);
    border-radius: 10px;
    opacity: 0.9;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.08px;
    padding: 15px;
  }

  &.toastify-content--warning {
    background: #FACD30;
    box-shadow: 0 8px 10px 0 rgba(62,69,80,0.29);
    border-radius: 10px;
    opacity: 0.9;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.08px;
    padding: 15px;
  }
}
