@import '@/styles/_variables.less';

.csv-export {
  width: 100%;
  margin-top: 27px;

  .p-accordion-tab {
    .p-accordion-header {
      .p-accordion-header-text {
        text-align: left;
      }
    }
  }

  .p-button {
    background-color: #174fe3;
  }

  .p-clearfix {
    font-size: 14px;
  }

  .input-container {
    margin-right: 40px;

    .label {
      text-align: left;
      font-weight: bold;
      font-size: 12px;
      color: #1a1a1c;
      line-height: 20px;
      margin-bottom: 4px;
    }

    .select-all-employee-div {
      display: flex;
      justify-content: space-between;

      .p-checkbox {
        width: 15px;
        height: 15px;
        margin-bottom: 2px;

        .p-checkbox-box {
          width: 15px;
          height: 15px;
          min-width: 15px;
          min-height: 15px;
        }
      }
    }

    .auto-complete-style {
      width: 216px;
    }

    .calendar-style {
      width: 200px;
    }

    .drop-down-textfield-style {
      width: 216px;

      .p-inputtext {
        justify-content: flex-start;
      }
    }
  }

  .selected-employee-container {
    text-align: left;

    .employee-list {
      display: flex;
      align-items: center;
      margin-left: 8px;
      margin-bottom: 4px;

      .employee-name {
        font-size: 16px;
        width: 145px;
        line-height: 27px;
      }

      .btn-style {
        color: rgba(0, 0, 0, 0.54);
        cursor: pointer;
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 60px;

    .btn-style {
      background-color: #174fe3;
      font-size: 16px;
    }
  }

  .validation-error-message {
    font-size: 0.75rem;
    text-align: start;
    color: @custom-red;
  }

  .select-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;

    .left-item {
      display: flex;
      align-items: center;

      .checkbox {
        margin-right: 8px;
      }
    }
  }

  .p-orderlist-item {
    padding: 0.4rem 1rem !important;
  }

  .p-orderlist-controls {
    display: none !important;
  }
}