.fp-architect-editor, .fp-architect-menu-editor {
  position: fixed;
  background:rgba(0,0,0,0.4);
  top:0%;
  right:0%;
  bottom:0%;
  left:0%;
  z-index: 15000;

  .fp-modal-content {
    background:white;
    position: absolute;
    top:10%;
    right:10%;
    bottom:10%;
    left:10%;
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.3);
  }

  i.close {
    position: absolute;
    right:0;
    top:0;
    color:white;
    font-size:20px;
    cursor:pointer;
  }

  iframe {
    border:0;
    width:100%;
    height:100%;
  }
}

.loader-architect-container {
  position: fixed;
  background:rgba(0,0,0,0.4);
  top:0%;
  right:0%;
  bottom:0%;
  left:0%;
  z-index: 15000;
}

.loader-architect {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    position: absolute;
    left:50%;
    top:50%;
    margin:-60px 0 0 -60px;
    animation: spinarchitect 2s linear infinite;
}

@keyframes spinarchitect {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
