@import '@/styles/_variables.less';

.config-update-page {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .message-container {
    >.p-inline-message.p-component {
      padding: 1rem 2rem !important;
      font-size: 1.25rem !important;
      line-height: 1.5rem !important;
      background: #259AFA;
      color: white;
      font-weight: 500;

      .pi.pi-exclamation-circle {
        font-size: 1.5rem;
      }
    }
  }

  .refresh-button-container {
    .p-button.refresh-button {
      width: 180px;
      height: 44px;
    }
  }
}