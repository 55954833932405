@import '@/styles/_variables.less';

.dialog-body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  gap: 4rem;

  @media (max-width: @mobile-size) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .date-time-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: auto;

    @media (max-width: @mobile-size) {
      flex-direction: column;
      padding-bottom: 10px;
      margin-bottom: 1rem;
    }

    &.wireless-channel {
      .p-dropdown {
        width: 120px;
      }
    }

    .label {
      text-align: left;
      font-weight: bold;
    }

    .inner-section {
      display: flex;
      margin-top: 1rem;
      min-height: 50px;
      gap: 10px;
      flex: 1;
      align-items: flex-end;
    }
  }

  .dialog-button {
    text-align: center;
    justify-content: flex-center;
    position: relative;

    .csv-download {
      width: 170px;
    }

    .no-device {
      color: @custom-red;
      position: absolute;
      left: 0.25rem;
      white-space: nowrap;
      top: -1.5rem;
      background: white;
      font-weight: 500;
      font-size: 0.875rem;
    }
  }
}

.note {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.46px;
  color: @custom-red;
  padding-left: 2rem;
  text-align: left;

  @media (max-width: @mobile-size) {
    margin: 0;
    font-size: 0.875rem;
    margin-left: 0.5rem;
  }
}