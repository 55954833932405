@import '@/styles/_variables.less';

.area-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .config-content {
    padding-top: 1.5rem;
  }

  .setting-content {
    width: 100%;
    height: 100%;

    .panel {
      display: flex;
      justify-content: space-between;
      // height: calc(100% - 60px);
      padding-top: 1.5rem;
      gap: 2rem;

      .title-text {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 0.75rem;
        font-weight: 500;
      }

      .floor-list-container {
        min-width: 240px;
        max-width: 300px;

        .floor-list {
          padding: 0.5rem;
          height: auto;
          overflow: auto;
          border: 2px solid #0000005C;
          border-radius: @custom-border-radius;
          background-color: white;

          .floor-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: normal;
            text-align: left;
            padding: 0.5rem;
            font-weight: 500;
            font-size: 0.875rem;

            &.checked {
              color: @custom-selected-color;
              font-weight: bolder;
            }
          }
        }
      }

      .floor-map-container {
        width: 100%;
        min-width: 500px;

        .title-text {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -2.5rem;
          padding-bottom: 1.8rem;

          >div {
            flex: 1;
          }

          .marker-toggle-container {
            margin-left: 0.5rem;
            text-align: left;
            position: relative;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            label {
              font-size: 0.875rem;
            }

            .p-multiselect.p-component {
              max-width: 350px;
              min-width: 350px;
              border: 2px solid #0000005C;
              border-radius: @custom-border-radius;
              height: 40px;

              .p-multiselect-label {
                padding-left: 0.25rem;
                padding-right: 0.25rem;

                .p-multiselect-token {
                  padding: 0.125rem 0.25rem;
                  border-radius: @custom-border-radius;
                }
              }
            }
          }

          .empty-div {
            max-width: 200px;
            min-width: 200px;
          }
        }

        .floor-map {
          width: 100%;
          height: auto;
          padding: 0.5rem;
          margin-top: -1rem;

          .image-marker {
            height: auto;
            margin-top: 0;
            border-radius: @custom-border-radius;
            border: 2px solid transparent;

            .image-marker__image {
              border-radius: @custom-border-radius;
            }

            .map-marker-style {
              font-weight: bold;
              font-size: 1.2rem;
              text-shadow:
                2px 0 white,
                0 -2px white,
                -2px 0 white,
                0 2px white;
            }
          }

          .image-marker-tools {
            margin-top: -4.5rem;
            padding-bottom: 1rem;
          }
        }
      }

      .area-list-container {
        min-width: 300px;
        max-width: 360px;

        .area-list {
          padding: 0;
          height: auto;
          max-height: 500px;
          overflow: auto;
          border: 2px solid #0000005C;
          border-radius: @custom-border-radius;
          overflow: auto;
          background-color: white;

          .area-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 36px;
            padding: 0.25rem 0.75rem;

            &:last-child {
              border-bottom: none;
            }

            .area-icon {
              width: 20px;
              height: 20px;
              background-color: #10b981;
              border: 1px solid @border-color;
            }

            .area-name {
              width: calc(100% - 80px);
              height: 100%;
              text-align: left;
              display: flex;
              align-items: center;
              margin-left: 0.5rem;

              .name-button {
                padding: 0.25rem;
                height: auto;
                text-align: left;
                font-size: 0.875rem;
              }
            }

            .area-button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 60px;

              .edit-button,
              .trash-button {
                padding: 0;
                height: auto;
              }
            }
          }
        }

        .add-new-area-button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .p-button-label {
            text-align: left;
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-start;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .device-area-dialog {
    .p-dialog-header {
      padding-bottom: 0.25rem;
    }

    .area-device-message-container {
      .p-tabview {
        .p-tabview-nav-container {
          padding: 0.25rem 0.5rem;
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: #ffffff;

          .p-unselectable-text {
            width: 50%;
          }

          .p-tabview-nav-link {
            padding: 0.5rem;
            justify-content: center;
          }
        }
      }

      .p-tabview-panels {
        padding: 0.25rem 0.5rem;
      }

      .area-device-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .capacity {
          margin-left: 2rem;
          flex: 1;
          font-size: 0.875rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          label {
            width: 7rem;
          }

          .capacity-input {
            width: 100px;
          }
        }
      }

      .device-list-container {
        width: 100%;

        .category-dropdown-container {
          width: 100%;
          margin-top: 0.25rem;
          margin-bottom: 0.5rem;
          max-width: 240px;

          .custom-dropdown {
            width: 100%;
            margin-bottom: 0.25rem;
            padding: 0;

            .device-category-dropdown {
              &.p-dropdown {
                height: 40px;
                width: 100%;
                border: 2px solid #0000005C;
                border-radius: @custom-border-radius;

                .p-dropdown-label {
                  justify-content: center;
                  font-size: 1rem;
                }

                .p-dropdown-trigger {
                  display: flex;
                }
              }
            }
          }
        }

        .device-list {
          padding: 0;
          height: auto;
          max-height: 400px;
          overflow: auto;
          overflow: auto;
          background-color: white;

          .device-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 36px;
            border-bottom: 1px solid #eeeeee;

            &:last-child {
              border-bottom: none;
            }

            .device-label {
              width: 50%;
              height: 100%;
              text-align: left;
              display: flex;
              align-items: center;
              padding: 0.25rem 0.25rem;

              .marker-button {
                padding: 0;
                height: auto;
              }

              .marker-button-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 0.875rem;
                font-weight: 600;
                color: #333333;

                &.not-set-position {
                  font-weight: 400;
                }

                .marker-button-icon {
                  min-width: 30px;
                }
              }
            }

            .device-zone {
              width: 30%;
              margin-left: 1rem;
              font-size: 0.875rem;
              text-align: left;

              &.same-area {
                color: @custom-selected-color;
                font-weight: 500;
              }
            }

            .button-container {
              width: 20%;
              height: 100%;
              cursor: pointer;
              padding: 0.25rem;
              color: lightcoral;

              &:hover {
                color: red;
              }

              .add-area-button,
              .trash-button {
                padding: 0;
                height: auto;
              }
            }

          }
        }
      }
    }
  }
}

.marker-toggle-panel.p-multiselect-panel.p-component {
  .p-multiselect-header {
    margin: 0;
    padding: 0.75rem 1rem;
    background-color: transparent;

    .p-multiselect-items-wrapper {
      margin-top: -0.5rem;
    }
  }
}