@import '@/styles/_variables.less';

.camera-zone-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;
    height: 100%;

    .panel {
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .title-text {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }

      .floor-list-container {
        min-width: 240px;
        max-width: 300px;

        .floor-list {
          padding: 0.5rem;
          height: auto;
          overflow: auto;
          border: 2px solid #0000005C;
          border-radius: @custom-border-radius;
          background-color: white;

          .floor-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: normal;
            text-align: left;
            padding: 0.5rem;
            font-weight: 500;
            font-size: 0.875rem;

            &.checked {
              color: @custom-selected-color;
              font-weight: bolder;
            }
          }
        }
      }

      .floor-map-container {
        width: 100%;
        min-width: 500px;

        .floor-map {
          width: 100%;
          height: auto;
          padding: 0.5rem;

          .image-marker {
            height: auto;

            .map-marker-style {
              font-weight: bold;
              font-size: 1.2rem;
              text-shadow:
                2px 0 white,
                0 -2px white,
                -2px 0 white,
                0 2px white;
            }

          }
        }
      }

      .device-list-container {
        min-width: 360px;
        max-width: 400px;

        .device-list {
          padding: 0;
          height: auto;
          max-height: 700px;
          overflow: auto;
          border: 2px solid #0000005C;
          border-radius: @custom-border-radius;
          overflow: auto;
          background-color: white;

          .p-tabview {
            .p-tabview-nav-container {
              padding: 0.25rem 0.5rem;
              position: sticky;
              top: 0;
              z-index: 1;
              background-color: #ffffff;

              .p-unselectable-text {
                flex: 1;
              }

              .p-tabview-nav-link {
                padding: 0.5rem;
                justify-content: center;
              }
            }
          }

          .p-tabview-panels {
            padding: 0.25rem 0;
          }

          .device-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 36px;
            border-bottom: 1px solid #dddddd;
            padding-left: 0.25rem;
            padding-right: 0.25rem;

            &.is-edit {}

            &:last-child {
              border-bottom: none;
            }

            .device-label {
              width: 80%;
              height: 100%;
              text-align: left;
              display: flex;
              align-items: center;
              padding: 0.25rem 0.25rem;

              .marker-button {
                padding: 0;
                height: auto;
              }

              .marker-button-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 0.875rem;
                color: #333333;
                width: 100%;

                &.not-set-position {
                  font-weight: 400;
                }

                .marker-button-icon {
                  min-width: 30px;
                }

                .device-zone-name-container {
                  display: flex;
                  align-items: flex-start;
                  justify-content: flex-start;
                  flex-direction: column;
                  width: 100%;

                  .device-name {
                    font-weight: 600;
                  }

                  .zone-dropdown {
                    width: 100%;
                    margin-top: 0.25rem;
                    height: 40px;

                    .p-dropdown-label {
                      padding: 0.5rem;
                      color: @custom-selected-color;
                    }
                  }

                  .verkada-zone-input {
                    width: 100%;
                    margin-top: 0.25rem;
                    font-weight: 600;
                    box-shadow: none;

                    &.has-error {
                      border-color: @custom-red;
                      color: @custom-red;
                    }
                  }

                  .zone-name {
                    font-weight: normal;
                    font-size: 0.875rem;
                    width: 100%;

                    >span {
                      word-wrap: anywhere;
                    }

                    &.has-error {
                      border-color: @custom-red;
                      color: @custom-red;
                    }
                  }
                }
              }
            }

            .edit-button-container {
              width: 20%;
              height: 100%;
              cursor: pointer;
              padding: 0.25rem;

              .edit-button {
                padding: 0;
              }
            }

          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-start;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}