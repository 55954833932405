@import '@/styles/_variables.less';

.device-status-overview-now {
  .main-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 850px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    .image-container {
      width: calc(100% - 720px);
      padding: 0.25rem;

      @media (max-width: 850px) {
        width: 100%;
        margin-top: 32px;
        padding: 0;
      }

      .image-marker-tools {
        padding-top: 0;
        padding-bottom: 0;
        width: 160px;
        margin-left: auto;
      }

      .image-marker {
        img {
          height: auto;
          width: 100%;
        }
      }
    }

    .table-container {
      width: 700px;
      padding: 0.25rem;

      @media (max-width: 850px) {
        width: 100%;
        padding: 0;
      }

      &.history {
        width: 100% !important;
      }

      .status-icon {
        width: 28px;
        height: 28px;
        background-color: white;
        border: 1px solid #555555;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;

        &.status-online {
          background-color: #008000;
        }

        &.status-offline {
          background-color: #ff0000;
        }

        &.status-dormant {
          background-color: #d3d3d3;
        }

        &.status-alerting {
          background-color: #ffd700;
        }

        &.status-unknown {
          background-color: #000000;
        }
      }

      .p-filter-column {
        .p-inputtext {
          padding: 0.25rem 0.5rem;
          border: 1px solid #d4d4d8;
          height: 34px;
        }

        .p-column-filter-element {
          width: 100%;
        }

        .p-column-filter-menu-button {
          display: none;
        }

        .p-column-filter-clear-button {
          display: none;
        }
      }
    }
  }
}