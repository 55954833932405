.underconstruction-container {

  #backgroundImage1,
  #backgroundImage2,
  .ovarlay,
  .infoText {
    left: 70px;
    top: 70px;
    right: 70px;
    bottom: 70px;
    margin: auto;
    position: absolute;
    border-radius: 1rem;
  }

  backgroundImage,
  #backgroundImage1,
  #backgroundImage2 {
    background-color: #555;
    background-image: url('https://unsplash.it/1200/750/?random&0');
    background-position: center center;
    background-size: cover;
  }

  .ovarlay {
    background: rgba(0, 0, 0, .6);
    z-index: 5;
  }

  .infoText {
    font-size: 80px;
    text-align: center;
    top: 40%;
    background: none;
    letter-spacing: 10px;
    z-index: 6;
    padding: 10px;
    color: white;
    // add box shadow
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  }

  .infoText span {
    font-size: 14px;
    letter-spacing: 40px;
    display: block;
  }

  .hide {
    opacity: 0;
    -webkit-transition: all 3s 3s ease-in-out;
    -moz-transition: all 3s 3s ease-in-out;
    -ms-transition: all 3s 3s ease-in-out;
    -o-transition: all 3s 3s ease-in-out;
    transition: all 3s 3s ease-in-out;
    z-index: 2;
  }

  .show {
    opacity: 1;
    -webkit-transition: all 3s 3s ease-in-out;
    -moz-transition: all 3s 3s ease-in-out;
    -ms-transition: all 3s 3s ease-in-out;
    -o-transition: all 3s 3s ease-in-out;
    transition: all 3s 3s ease-in-out;
    z-index: 3;
  }

  .scaleImage {}

  @keyframes slidein {
    from {}

    to {}
  }

  @media screen and (max-width: 768px) {
    .infoText {
      font-size: 40px;
      letter-spacing: 5px;
    }

    .infoText span {
      letter-spacing: 20px;
    }
  }

  @media screen and (max-width: 400px) {
    .infoText {
      font-size: 20px;
      letter-spacing: 1px;
    }

    .infoText span {
      font-size: 10px;
      letter-spacing: 10px;
    }
  }
}