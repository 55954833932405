@import '@/styles/_variables.less';

.config-container {
  &.employee-locations {
    padding: 1rem;
    align-items: flex-start;

    .image-container {
      width: 100%;
      padding: 0.5rem;

      @media (max-width: @mobile-size) {
        padding: 0;
        margin-top: 2rem;
      }

      .image-marker-tools {
        padding-top: 0;
        padding-bottom: 0;
        width: 160px;
        margin-left: auto;
      }

      .image-marker {
        img {
          height: auto;
          width: 100%;
          max-height: calc(100vh - 340px);
        }
      }

      .switch-all-devices-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: -32px;
        gap: 1rem;

        @media (max-width: 1200px) {
          width: 100%;
          padding-left: 0;
          justify-content: flex-start;
        }

        @media (max-width: @mobile-size) {
          width: auto;
          justify-content: flex-start;
          font-size: 0.875rem;
        }

        @media (max-width: @mobile-sm-size) {
          width: auto;
          justify-content: flex-start;

          label {
            white-space: wrap;
            width: 140px;
            font-size: 0.875rem;
            text-align: left;
            line-height: 1.1;
          }
        }
      }
    }
  }
}

.p-tooltip.presence-status-tooltip {
  border-radius: 5px;
  z-index: 1000;

  .p-tooltip-text {
    background-color: #f5f5f5;
    background-color: #f5f5f5;
    border: 2px solid #555555;
    box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);
    padding-top: 0px;
    padding-bottom: 0px;
    color: #444444;
    margin-top: 0px;
    margin-bottom: 0px;

    .tooltip-content {
      display: flex;
      flex-direction: column;
      max-width: 250px;
      height: auto;
      z-index: 110;
      opacity: 1;
      color: #444444;
      margin-top: 0px;
      margin-bottom: 0px;

      .tooltip-content-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1px;
        padding-top: 1px;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }
}