@import '@/styles/_variables.less';
@table-container-width: 840px;
@meetingroomusage-mobile-size: 1265px;

.config-container {
  &.meeting-room-usage {
    padding: 1rem;
    align-items: flex-start;

    .text-left {
      @media (max-width: @mobile-size) {
        flex-wrap: wrap;
      }

      .title-text {
        font-weight: bold;
        font-size: 1.25rem;
      }
    }

    .config-content {
      justify-content: flex-start;
    }

    .main-container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      @media (max-width: @meetingroomusage-mobile-size) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
      }

      .image-container {
        width: 100%;
        margin-right: 0.25rem;

        @media (max-width: @mobile-size) {
          display: none;
        }

        @media (max-width: @meetingroomusage-mobile-size) {
          margin-top: 2rem;
          padding: 0;
          width: 100%;
          min-width: auto;
        }

        .image-marker-tools {
          padding-top: 0;
          padding-bottom: 0;
          width: 160px;
          margin-left: auto;
        }

        .image-marker {
          img {
            height: auto;
            width: 100%;
          }
        }
      }

      .table-container {
        margin-left: 0.25rem;
        width: 100%;
        max-width: @table-container-width;

        @media (max-width: @meetingroomusage-mobile-size) {
          width: 100%;
          margin-left: 0;
        }

        .p-datatable-thead {
          @media (max-width: @meetingroomusage-mobile-size) {
            .p-frozen-column {
              position: inherit;
            }
          }
        }

        .p-datatable-tbody {
          td {
            padding: 0.125rem 0.5rem;
            text-align: center;

            @media (max-width: @mobile-sm-size) {
              padding: 0.25rem 0.25rem;
            }
          }

          @media (max-width: @meetingroomusage-mobile-size) {
            .p-frozen-column {
              position: inherit;
            }
          }

          .room-name-button {
            width: 100%;
            padding: 0;
            text-align: left;
            text-decoration: underline;

            @media (max-width: @mobile-sm-size) {
              font-size: 0.75rem;
            }
          }

          .organizer-button {
            padding: 0 0.5rem;
            font-size: 0.85rem;
            height: 36px;
            color: #3f3f46;
          }

          .room-status {
            width: 28px;
            height: 28px;
            background-color: white;
            border: 2px solid #555555;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;

            &.vacant {
              background-color: #008000;
            }

            &.occupied {
              background-color: #ff0000;
            }
          }

          .organizer-sp-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            font-size: 0.85rem;

            @media (max-width: @mobile-sm-size) {
              font-size: 0.75rem;
            }

            .sp-row {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 0.125rem;
              width: 100%;

              .left-title {
                min-width: 70px;
              }

              .right-value {
                font-weight: 500;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                .organizer-button {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }

    .meeting-room-usage-sidebar {
      .time-row-item-list {
        border: 1px solid @border-color;
        padding: 1rem;
        border-radius: @custom-border-radius;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        background-color: white;

        .time-row-item {
          display: flex;
          align-items: center;
          height: 40px;
          min-height: 40px;
          max-height: 40px;
          width: 100%;

          &.not-occupied {
            &:hover {
              border: 2px solid #333;
            }
          }

          .time-value {
            font-size: 1rem;
            width: 100px;
            font-weight: bold;
            color: #333333;
            margin-right: 0.5rem;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .time-content {
            width: 100%;
            height: 100%;

            > div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .occupied {
              background-color: @custom-red-alpha-8;
            }

            .available {
              background-color: @light-color;

              .new-meeting-button {
                width: 100%;
                padding: 0;
                color: @light-color;
                border-radius: 0;
                font-size: 1.15rem;

                &:hover {
                  color: @custom-selected-color;
                  font-weight: bolder;
                  color: #191970;
                }
              }
            }
          }
        }
      }
    }
  }
}
