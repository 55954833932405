.config-container {
  background-color: @config-content-bg-color;
  box-shadow: 4px 4px 4px 0px #00000040;
  border-radius: 1.5rem;

  @media (max-width: @mobile-size) {
    border-radius: @custom-border-radius;
  }

  &.pc-mode-enabled {
    min-width: 1280px;
  }

  .multiselect-style {
    max-width: 616px;
    width: 100%;
    min-width: 220px;

    @media (max-width: @mobile-size) {
      max-width: 100%;
      margin-top: 8px;
    }
  }

  .performance-config-title-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    .title-container {
      width: 20%;

      @media (max-width: 850px) {
        width: 100%;
      }
    }

    .performance-config-container {
      width: 80%;

      @media (max-width: 850px) {
        margin-top: 16px;
        width: 100%;
      }

      .upper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
      }

      .lower {
        width: 100%;

        .multiselect-container {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .config-content {
    width: 100%;
    height: auto;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: @custom-border-radius;

    .title-chart-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.25rem 0.5rem;
      width: 100%;

      .title-chart {
        font-weight: bold;
      }

      .title-chart__right {
        .p-multiselect-label {
          text-align: left;
        }
      }
    }

    .network-container {
      width: 100%;
      padding: 1rem;
      border-radius: 5px;
      margin-bottom: 1rem;

      @media (max-width: @mobile-size) {
        padding: 0;
      }

      .network-title-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 1rem;
        background-color: #dcdcdc;
        color: rgba(0, 0, 0, 0.87);
        padding: 0.75rem 0.75rem;
        border-radius: 0.5rem;
        // box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);

        @media (max-width: @mobile-size) {
          text-align: left;
        }
      }

      .network-content {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }

  &.custom-config-container {
    max-width: 100%;
    min-height: calc(100vh - 100px);
    height: calc(100vh - 100px);
    padding: 1.5rem 1rem;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    @media (max-width: @mobile-size) {
      padding: 0.5rem;
    }

    .config-title-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: auto;
      margin-bottom: 0.5rem;

      .title-text {
        font-size: 1.25rem;
        font-weight: 500;
      }
    }

    &[class$='-setting-container'] {
      .p-dropdown {
        background-color: white;
      }

      .p-multiselect {
        background-color: white;
      }
    }
    &[class$='signage-components-container'] {
      .p-dropdown {
        background-color: white;
      }

      .p-multiselect {
        background-color: white;
      }
    }
  }

  // for display floor map
  .image-marker {
    margin: 0.25rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    /* or block if you want it to be a block element */
    position: relative;
    /* optional */

    .image-marker__image {
      &.custom-image-marker {
        width: auto;
      }
    }

    .custom-marker {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 1px solid #555555;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 850px) {
        @mobile-width: calc(100vw / 24);
        width: @mobile-width !important;
        height: @mobile-width !important;
      }

      .text-count {
        font-size: 2rem;
        font-weight: bold;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0.25rem;

        @media (max-width: 850px) {
          font-size: 1rem !important;
        }
      }

      .face-image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid #5f8d4e;

        img {
          display: block;
          max-width: 100%;
          height: auto;
        }
      }

      &.device-status-marker {
        &.device-status-marker-icon {
          border-radius: 0;
          border: 0;

          .face-image {
            border-radius: 0;
            border: 0;
            background-color: transparent;
            width: 40px;
            height: 40px;

            img {
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

.p-tooltip.p-component.p-tooltip-active {
  .p-tooltip-text {
    background-color: #f5f5f5;
    border: 2px solid #555555;
    box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);
    color: #444444;
  }
}

.p-tooltip.custom-marker-tooltip {
  border-radius: 5px;
  min-width: 280px;
  max-width: 480px;

  .p-tooltip-text {
    background-color: #f5f5f5;
    border: 2px solid #555555;
    box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);
    color: #444444;

    .display-marker {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .display-marker__container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        padding-bottom: 0.125rem;
        padding-top: 0.125rem;

        .text-label {
          text-align: left;
          white-space: nowrap;
        }

        .text-value {
          text-align: right;
          padding-left: 0.125rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}

.chart-container {
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: @custom-border-radius;
}

.p-component {
  &.p-inputtext {
    padding: 0.25rem 0.75rem;
    height: 40px;
    font-size: 0.9rem;
    border: 2px solid #0000005c;
    border-radius: @custom-border-radius;

    &.p-invalid {
      border-width: 2px;
      border-color: @custom-red;
    }
  }

  &.p-button {
    height: 40px;
    border-radius: @custom-border-radius;

    @media (max-width: @mobile-size) {
      height: 36px;
    }

    &.refresh-button {
      background-color: #174fe3;
      border-color: #174fe3;
      width: 88px;
    }

    &.submit-button {
      background-color: #174fe3;
      border-color: #174fe3;
    }

    &.reset-button {
      background-color: #818181;
      border: 0;
    }

    &.back-button {
      background-color: #818181;
      width: 88px;
      border: 0;
    }

    &.add-button {
      background-color: #818181;
      border: 0;
    }

    &.p-button-info {
      &.has-shadow {
        box-shadow: 4px 4px 4px 0px #527dee;
      }
    }

    &.p-button-secondary {
      &.has-shadow {
        box-shadow: 4px 4px 4px 0px #aeaeae;
      }
    }

    &.p-button-success {
      &.has-shadow {
        box-shadow: 4px 4px 4px 0px #4ddb81;
      }
    }
  }

  &.p-selectbutton {
    &.p-button-group {
      border-radius: @custom-border-radius;
      box-shadow: 4px 4px 4px 0px #aeaeae;
      .p-button {
        border-radius: 0;
        &:first-of-type {
          border-top-left-radius: @custom-border-radius;
          border-bottom-left-radius: @custom-border-radius;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-of-type {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: @custom-border-radius;
          border-bottom-right-radius: @custom-border-radius;
        }
      }
    }
  }

  &.p-dropdown {
    border: 2px solid #0000005c;
    border-radius: @custom-border-radius;
    background: transparent;
  }

  &.p-inputwrapper {
    height: 40px;

    &.p-invalid {
      .p-inputtext {
        border-width: 2px;
        border-color: @custom-red;
      }
    }

    .p-multiselect-label-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .p-multiselect-label {
      font-size: 0.875rem;
      padding: 0;

      .p-multiselect-token {
      }
    }

    .p-dropdown-label {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .p-inputtext {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .p-datepicker-trigger {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.p-confirm-dialog {
    min-width: 320px;

    .p-confirm-dialog-message {
      width: 100%;
    }

    .p-dialog-content {
      min-height: 80px;
    }
  }

  &.p-inputswitch {
    .p-inputswitch-slider {
    }

    &.p-focus {
      .p-inputswitch-slider {
        box-shadow: 0 0 0 2px @custom-selected-color;
      }
    }

    &.p-inputswitch-checked {
      &:not(.p-disabled):hover {
        .p-inputswitch-slider {
          background: #0070ff;
        }
      }

      .p-inputswitch-slider {
        background: @custom-selected-color;
      }
    }
  }

  &.p-checkbox {
    width: 20px;
    height: 20px;

    &.p-checkbox-checked {
      .p-checkbox-box {
        // background-color: @custom-selected-color;
        // border-color: @custom-selected-color;
      }
    }

    .p-checkbox-box {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;

      &:hover {
        // background-color: @custom-selected-color;
        // border-color: @custom-selected-color;
      }

      .p-checkbox-icon {
        font-weight: 700;
      }
    }

    .p-checkbox-box.p-highlight {
      &:hover {
        // background-color: @custom-selected-color;
        // border-color: @custom-selected-color;
        // color: @text-white-color;
      }
    }
  }

  &.p-multiselect {
    border: 0;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
    height: 49px;
    background-color: transparent;
  }

  &.p-multiselect-panel {
    .multi-select-item-template {
      display: flex;
      align-items: center;
    }
  }

  &.p-slider {
    .p-slider-range {
      background: @custom-selected-color;
    }

    .p-slider-handle {
      border-color: @custom-selected-color;

      &:not(.p-disabled):hover {
        border-color: @custom-selected-color;
        background: @custom-selected-color;
      }

      &:focus {
        box-shadow: 0 0 0 1px @custom-selected-color;
      }
    }
  }

  &.p-toast {
    opacity: 1;
  }

  .p-toast-message {
    .p-toast-message-content {
      color: white;
      border-radius: 8px;
      padding: 1rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .p-toast-message-icon {
        color: white;
        width: 1.5rem !important;
        font-size: 1.5rem !important;
      }

      .p-toast-icon-close-icon {
        color: white;
      }

      .p-toast-message-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .p-toast-detail {
          text-align: left;
        }
      }
    }

    &.p-toast-message-error {
      background: @custom-red;

      .p-toast-message-content {
        background: @custom-red;
      }
    }

    &.p-toast-message-success {
      background: @success-color;

      .p-toast-message-content {
        background: @success-color;
      }
    }

    &.p-toast-message-info {
      * {
        color: #2563eb;
      }
    }
  }

  &.p-sidebar {
    width: 100%;
    max-width: 500px;
    min-width: 320px;
    background-color: #eeeeee;

    @media (max-width: @mobile-size) {
      border: 1px solid @border-color;
      border-radius: @custom-border-radius;
    }

    .p-sidebar-header {
      justify-content: flex-start;

      @media (max-width: @mobile-size) {
        padding: 0.85rem;
      }

      .p-sidebar-close {
        border: 1px solid #dddddd;
        border-radius: 50%;
        background-color: @white;
      }
    }

    .p-sidebar-content {
      @media (max-width: @mobile-size) {
        padding: 0.85rem;
      }
    }
  }

  .p-dialog-footer {
    padding-top: 1.5rem;
  }
}

.p-sidebar-mask.p-component-overlay {
  @media (max-width: @mobile-size) {
    padding: 0.75rem;
  }
}

.spinner-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.no-data-container {
  margin-top: 0.5rem;
  background-color: @custom-red;
  color: @text-white-color;
  font-weight: 600;
  max-width: 160px;
  padding: 0.25rem 0.5rem;
  border-radius: @custom-border-radius;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.image-marker-tools.p-buttonset {
  .p-button {
    border-radius: 0;
    &:hover {
      background-color: @custom-selected-color;
      color: @white;
      border-color: @custom-selected-color;
      font-weight: bold;
    }
    &:first-child {
      border-top-left-radius: @custom-border-radius;
      border-bottom-left-radius: @custom-border-radius;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: @custom-border-radius;
      border-bottom-right-radius: @custom-border-radius;
    }
  }
}
