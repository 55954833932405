@import '@/styles/_variables.less';
// DynamicParameterHeader stylesheet

.side-bar {
  .menu-lists {
    .sidebar-category-container {
      &:not(:first-child) {
        margin-top: 1rem;
      }

      .sidebar-category {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 0.875rem;
        font-weight: normal;
      }

      hr {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 3px;
      }
    }
  }
}

.sidebar-menulist-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;

  .header-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1rem;
    font-size: 0.875rem;

    .header-text-label {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 2px;
      margin-bottom: 2px;
      font-weight: normal;
    }
    hr {
      width: 100%;
      margin-top: 0;
    }
  }

  .select-box {
    background-color: transparent;
  }

  .selectbox__control {
    margin-bottom: 5px;
    font-size: 0.875rem;
    background: transparent;
    color: white;
    border: 0;

    .selectbox__single-value {
      color: white;
      font-weight: 500;
    }

    .selectbox__indicators {
      color: white;
      .selectbox__indicator-separator {
        display: none;
      }
    }
  }

  .selectbox__menu {
    color: white;
    background: @sidebar-background;
    border: 1px solid white;

    .selectbox__option {
      position: relative;
      cursor: pointer;
    }

    .selectbox__option--is-selected {
      background: transparent;
      font-size: inherit;
      font-weight: bold;
      text-decoration: none;
      color: @custom-selected-color;
    }

    .selectbox__option--is-focused {
      font-weight: bold;
      background: transparent;
      color: @custom-selected-color;
      font-weight: bold;
      font-size: inherit;
      text-decoration: underline;
    }
  }
}

.header-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hide-header-text {
  display: none;
}
