@import '@/styles/_variables.less';

.config-container {
  &.signage-components-overview-container {
    border-radius: 0;
    box-shadow: none;

    .action-updating-signage-container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
    }

    .setting-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .add-signage-container {
        display: flex;
        align-items: center;

        button {
          width: 240px;
          background-color: rgb(23, 79, 227);
          color: white;

          &:hover {
            background-color: rgba(23, 79, 227, 0.9);
          }
        }

        > span {
          margin-left: 2rem;
        }
      }

      .list-action-container {
        margin-top: 1.5rem;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        > form {
          width: 100%;
          max-width: 1280px;
        }
      }

      .action-item {
        background-color: @grey-light;
        border-radius: @custom-border-radius;
        padding-top: 0.25rem;
        padding-bottom: 0.125rem;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;

        .main-row-container {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: row;
          margin-bottom: 0.5rem;
          padding-left: 1rem;
          padding-right: 1rem;
          width: 100%;
          max-width: 100%;
          gap: 1rem;

          @media (max-width: @mobile-size) {
            padding-left: 0px;
          }

          &.is-header {
            margin-bottom: 0.2rem;
            font-size: 0.875rem;
            color: #1a1a1c;
            font-weight: 600;
            text-align: start;

            .numerical-order {
              height: 0;
            }
          }

          .numerical-order {
            align-items: center;
            width: 40px;
            height: 40px;
            padding-top: 5px;
          }

          .branch-field {
            width: 25%;
            min-width: 150px;

            .p-dropdown {
              width: 100%;

              .p-dropdown-trigger {
                width: 2rem;
              }
            }
          }

          .floor-field {
            width: 25%;
            min-width: 150px;

            .p-dropdown {
              width: 100%;

              .p-dropdown-trigger {
                width: 2rem;
              }
            }
          }

          .component-field {
            width: 25%;
            min-width: 150px;

            .p-dropdown {
              width: 100%;

              .p-dropdown-trigger {
                width: 2rem;
              }
            }
          }

          .interval-field {
            width: 15%;
            min-width: 100px;
            padding-right: 0px;

            .p-dropdown {
              width: 100%;

              .p-dropdown-trigger {
                width: 2rem;
              }
            }
          }

          .action-field {
            width: 60px;
            min-width: 60px;
          }

          .submit-button {
            margin-right: 1rem;
            width: 100px;
          }

          .add-button {
            width: 100px;
          }

          .input-text-field-container {
            position: relative;

            .validation-error-message {
              font-size: 0.65rem;
              position: absolute;
              right: 5px;
              top: -1.3rem;
              color: @custom-red;
              background: white;
              padding-left: 0.125rem;
              padding-right: 0.125rem;
              font-weight: bold;
            }
          }
        }
      }

      .main-row-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 1.5rem;
        width: 100%;
        max-width: 100%;

        @media (max-width: @mobile-size) {
          padding-left: 0px;
        }

        &.is-submit-button-container {
          margin-top: 1rem;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          align-self: flex-start;

          .submit-button {
            margin-right: 1rem;
            width: 100px;
          }

          .add-new-button {
            width: 100px;
          }
        }
      }
    }
  }
}

.custom-sidebar {
  width: 100%;
  max-width: 100% !important;
  background-color: white !important;

  @media (max-width: @mobile-size) {
    border: 1px solid @border-color;
    border-radius: @custom-border-radius;
  }

  .p-sidebar-header {
    justify-content: flex-start;

    @media (max-width: @mobile-size) {
      padding: 0.85rem;
    }

    .p-sidebar-close {
      border-radius: 50%;
      background-color: transparent;
      position: absolute;
      bottom: 20px;
      left: 20px;
      padding: 1.5rem 1.5rem;

      .p-sidebar-close-icon {
        font-size: 24px;
      }

      &:hover {
        border: 2px solid #333333;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .p-sidebar-content {
    @media (max-width: @mobile-size) {
      padding: 0.85rem;
    }
  }
}

.fade {
  transition: opacity 0.2s;
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
