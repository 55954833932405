@import '@/styles/_variables.less';

.network-container {
  &.air-quality-history {
    padding: 0 !important;
    padding-top: 1rem !important;
    align-items: flex-start;

    .network-content {
      padding-top: 0 !important;

      .sensor-menu-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 2rem;

        .timerange-select-container {
          background: transparent;
          transition: none;
          border-radius: 0.375rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        span {
          &.p-multiselect-token-label {
            width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .data-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 16px;

        .data-container__item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 0;
          padding: 0.5rem;
          width: 50%;

          @media (max-width: 850px) {
            width: 100%;
            padding: 0;
          }

          .chart-container {
            margin-top: 0.5rem;
            width: 100%;

            .recharts-responsive-container {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}