@import '@/styles/_variables.less';

.network-container {
  &.meeting-room-outlook-booking {
    padding: 1rem;
    align-items: flex-start;

    .network-tool-container {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      position: relative;
    }

    .network-content {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .data-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .data-container__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0;
        padding: 0.5rem;
        width: 50%;

        .chart-container {
          margin-top: 0.5rem;
          width: 100%;

          .recharts-responsive-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}