@import '@/styles/_variables.less';

.employee-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1rem;

  form {
    width: 100%;
  }

  @media (max-width: @mobile-size) {
    font-size: 0.85rem;
  }

  .name-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: @mobile-sm-size) {
      justify-content: space-between;
    }

    .employee-image {
      img {
        width: 60px;
        border-radius: 50%;
        border: 1px solid @border-color;
        background-color: @white;
      }
    }

    .employee-name {
      display: flex;
      flex-direction: column;
      margin-left: 1em;
      font-size: 1.15em;
      font-weight: bold;

      .employee-name-reading {
        font-size: 0.75rem;
        font-weight: 400;
      }

      @media (max-width: @mobile-sm-size) {
        margin-left: 0;
      }
    }
    .presence-status-color {
      width: 12px;
      height: 12px;
      background-color: white;
      border-radius: 50%;
      margin-left: 14px;
      margin-right: 4px;
      margin-top: 2px;
    }
    
    .employee-presence-chat-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .employee-presence-status {
        margin-left: 1em;
  
        span {
          font-size: 1em;
          padding: 2px 4px;
          border-radius: 3px;
          background-color: gray;
          color: white;
  
          &.presence {
            background-color: green;
          }
  
          &.not-presence {
            background-color: red;
          }
        }
      }

      @media (max-width: @mobile-sm-size) {
        flex-direction: column;
        gap: 0.25rem;
        margin-top: 14px;

        .employee-presence-status {
          margin-left: 0;
        }

        .presence-status-color {
          margin-left: 0;
        }
      }

      .employee-chattool-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .team-url {
      margin-left: 1em;

      @media (max-width: @mobile-sm-size) {
        margin-left: 0;
      }

      .team-button {
        background-color: @white;
        padding: 0;
        width: 60px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: @border-color;

        &:hover {
          background-color: @border-color;
          border-color: @white;
        }

        img {
          height: 80%;
          width: auto;
        }
      }
    }
  }

  .info-container {
    background-color: @white;
    border-radius: 0.45em;
    border: 1px solid @border-color;
    padding: 0.75rem 1em;
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    @media (max-width: @mobile-size) {
      padding: 0.5em 0.75em;
    }

    .row-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0.125em;
      height: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      font-weight: 500;

      .left-title {
        width: 6em;
        min-width: 6em;
        color: #808080;

        @media (max-width: @mobile-size) {
          width: 5em;
          min-width: 5em;
        }
      }

      .right-value {
        margin-left: 0.25em;
        font-size: 1em;
        font-weight: 600;
        overflow: hidden;

        &.email-value {
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.event-value {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .location-value {
            width: 50%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .subject-value {
            width: 50%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .copy-button-container {
        margin-left: 1.25em;

        .copy-button {
          padding: 0;
          height: 30px;

          .p-button-icon {
            font-size: 1.25em;
          }
        }
      }
    }

    &.location-info {
      .no-location {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        font-size: 1.25em;
        color: #808080;
      }

      .p-tabview {
        .p-tabview-nav-container {
          padding: 0.25rem 0.5rem;
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: #ffffff;

          .p-unselectable-text {
            flex: 1;
          }

          .p-tabview-nav-link {
            padding: 0.5rem;
            justify-content: center;
          }
        }
      }

      .p-tabview-panels {
        padding: 0.25rem 0;
      }

      .image-container {
        width: 100%;
        padding: 0.5em;

        @media (max-width: @mobile-size) {
          padding: 0;
          margin-top: 2em;
        }

        .image-marker-tools {
          padding-top: 0;
          padding-bottom: 0;
          width: 160px;
          margin-left: auto;
        }

        .image-marker {
          img {
            height: auto;
            width: 100%;
          }

          .custom-marker {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 1px solid #555555;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 850px) {
              @mobile-width: calc(100vw / 10);
              width: @mobile-width !important;
              height: @mobile-width !important;
            }

            .face-image {
              width: 100%;
              height: 100%;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              border: 2px solid #5f8d4e;

              img {
                display: block;
                max-width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }

    &.events-info {
      .no-events {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        font-size: 1.25em;
        color: #808080;
      }

      @media (max-width: @mobile-size) {
        .row-info {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          height: auto;
          border-bottom: 1px solid @border-color;
          padding-bottom: 0.25em;

          &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
          }

          font-weight: 500;

          .left-title {
            width: 100%;
            color: #808080;
          }

          .right-value {
            margin-left: 0;
            font-size: 1em;
            font-weight: 600;

            &.event-value {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;

              .location-value {
                width: 100%;
                font-weight: 400;
                white-space: wrap;
              }

              .subject-value {
                width: 100%;
                white-space: wrap;
              }
            }
          }
        }
      }
    }
  }
}
