@import '@/styles/_variables.less';

.network-container {
  &.meeting-room-outlook {
    .table-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      border-top: 1px solid #dddddd;
      padding-top: 1rem;

      .table-container-item {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 800px;
        height: 180px;
        margin-left: auto;
        margin-right: auto;

        .column-1,
        .column-2,
        .column-3,
        .column-4 {
          height: 100%;
          width: 22%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .header-column {
            min-height: 45px;
            height: 45px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            font-weight: bold;
            font-size: 0.9rem;
            color: #333333;
            border: 1px solid #dddddd;

            &.header-1 {
              background-color: rgb(161, 160, 160);
              color: white;
            }

            &.header-2 {
              background-color: #df5438;
              color: white;
            }

            &.header-3 {
              background-color: #cea000;
              color: white;
            }
          }

          .header-body {
            min-height: 45px;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border: 1px solid #dddddd;
            font-weight: bold;
            font-size: 1.2rem;
          }
        }

        .column-1 {
          width: 34%;
        }

        .column-2 {
          .header-body {
            font-size: 1.5rem;
          }
        }
      }
    }


    .outlook-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: #f5deb33b;
      padding: 1rem 0.25rem;
      border-radius: 0.5rem;

      .main-outside-div-container {
        width: 100%;
        flex-direction: column;
        margin-bottom: 1rem;
      }

      .main-div-item-container {
        margin-bottom: 5px;
        margin-right: 2rem;
        margin-left: 2rem;
        position: relative;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &.view-all {
          margin-bottom: 1rem;
          margin-top: 1rem;

          .outlook-title-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .slider-container {
              width: 100%;
              max-width: 400px;
              margin-left: 180px;
              margin-right: 0;
              position: relative;

              .time-slider-tooltip-start {
                position: absolute;
                top: 1rem;
                background-color: rgba(51, 51, 51, 0.3);
                padding: 2px 4px;
                border-radius: 3px;
                font-size: 0.9rem;
              }

              .time-slider-tooltip-end {
                position: absolute;
                top: -2rem;
                background-color: rgba(51, 51, 51, 0.3);
                padding: 2px 4px;
                border-radius: 3px;
                font-size: 0.9rem;
              }
            }
          }
        }

        &.empty-container {
          height: 0;
          margin-top: 4.0rem;
          margin-bottom: 1.5rem;

          .main-div-container {
            height: 0;
            border: 0;

            .time-label {
              position: absolute;
              width: 50px;
              text-align: center;
              font-weight: 500;
              line-height: 1rem;
              font-size: 0.8rem;
              color: #333333;
              transform: rotate(-45deg);
            }
          }
        }
      }

      .room-name {
        width: 180px;
        min-width: 180px;
        max-width: 180px;
        padding-right: 0.5rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: 0.9rem;
        text-transform: uppercase;

        .room-available-status {
          width: 20px;
          height: 20px;
          background-color: #ffffff;
          border-radius: 50%;
          border: 1px solid #333333;

          &.available {
            background: #008000;
          }

          &.unavailable {
            background: #FF0000;
          }
        }
      }

      .main-div-container {
        position: relative;
        width: calc(100% - 200px);
        height: 100%;
        background-color: #eeeeee;
        display: flex;
        align-items: center;
        border: 1px solid #dddddd;
        border-radius: @custom-border-radius;
        @width-tooltip: 60px;
        @height-tooltip: 32px;

        .now-line {
          position: absolute;
          width: 3px;
          z-index: 100;
          background: #19A7CE;

          .now-line-tooltip {
            position: absolute;
            top: -(@height-tooltip);
            width: @width-tooltip;
            height: @height-tooltip;
            left: -(@width-tooltip / 2);
            background-color: rgba(25, 167, 206, 0.7);
            color: white;
            font-weight: bold;
            border-radius: 5px;
            font-size: 1rem;
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .reverse-occupied-container {
          width: 100%;
          height: 100%;
          position: relative;
          overflow-x: hidden;
        }

        .child-div {
          height: calc(100%);
          top: 0px;
          bottom: 0px;
          position: absolute;
          border: 0;
          z-index: 99;
          border-right: 1px solid #dddddd;
          border-left: 1px solid #dddddd;

          .occupied-child-div {
            top: 0px;
            bottom: 0px;
            position: absolute;
          }

          .meeting-name {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bold;
            z-index: 100;
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .config-radio-content {
    display: flex;
    flex-direction: column;
  }

  .config-radio-spacing {
    margin-top: 10px;
  }

  .RCMAP-tooltip-body {
    border-radius: @custom-border-radius;
    padding: 1rem;

    .display-marker {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      .display-marker__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.9em;
      }
    }
  }
}

.p-tooltip.child-div-tooltip {
  border-radius: 5px;
  z-index: 110;

  .p-tooltip-text {
    background-color: #F5F5F5;
    background-color: #F5F5F5;
    border: 2px solid #555555;
    box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);
    color: #444444;
    font-weight: bold;

    .tooltip-content {
      display: flex;
      flex-direction: column;
      min-width: 220px;
      max-width: 440px;
      height: auto;
      z-index: 110;
      opacity: 1.0;
      color: #444444;

      .tooltip-content-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        padding-bottom: 0.125rem;
        padding-top: 0.125rem;

        &.header-item {
          border-bottom: 1px solid #333333;
          border-radius: 0.375rem;
        }

        &.subject {
          text-decoration: underline;
        }
      }
    }
  }

}