@import '@/styles/_variables.less';

.area-marker-target {
  max-height: auto;
  min-width: auto;
  min-height: auto;
  position: absolute;
  background-color: rgba(16, 185, 129, 0.6);
  width: 100%;
  height: 100%;
  text-align: center;
  color: #333;
  font-weight: bold;
  font-size: 0.875rem;
  border: 2px solid #333;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);

  &.capacity-zone {
    &-green {
      background-color: rgba(0, 168, 107, 0.6) !important;
    }

    &-lightgreen {
      background-color: rgba(152, 214, 58, 0.6) !important;
    }

    &-yellow {
      background-color: rgba(255, 225, 53, 0.6) !important;
    }

    &-orange {
      background-color: rgba(255, 153, 68, 0.6) !important;
    }

    &-red {
      background-color: rgba(210, 20, 4, 0.6) !important;
    }
  }

  .capacity-rate {
    &::after {
      content: '%';
      font-size: 70%;
    }
  }

  .count-view {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    color: white;
    -webkit-text-stroke: 1px #484848;
    color: white;
    text-shadow: 2px 2px 0 #484848, -1px -1px 0 #484848, 1px -1px 0 #484848,
      -1px 1px 0 #484848, 1px 1px 0 #484848;

    @media (max-width: @mobile-size) {
      font-size: calc(100vw / 36);
      text-shadow: 1px 1px 0 #333333, -1px -1px 0 #333333, 1px -1px 0 #333333,
        -1px 1px 0 #333333, 1px 1px 0 #333333;
    }

    @media (max-width: 500px) {
      text-shadow: none;
      -webkit-text-stroke: 0;
      color: #333333;
    }
  }
}

.moveable-dimension {
  position: absolute;
  background-color: #f5f5f5;
  border: 2px solid #555555;
  box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);
  color: #444444;
  border-radius: @custom-border-radius;
  width: auto;
  font-weight: bold;
  padding: 2px 6px;
  min-width: 6em;
  z-index: 903;
  font-size: 0.875rem;
  background-color: @custom-yellow;
}
