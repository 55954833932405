@import '@/styles/_variables.less';

.organization-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;

    .main-row-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      max-width: 1024px;
      min-width: 1024px;
      width: 100%;
      padding-left: 120px;
      
      @media (max-width: @mobile-size) {
        padding-left: 0px;
      }

      &.is-header {
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
        color: #1A1A1C;
        font-weight: 600;
        text-align: center;
      }

      &.is-submit-button-container {
        padding-left: 0;
        margin-top: 1rem;
        justify-content: flex-start;

        .p-button {
          width: 100px;
        }
      }

      .id-field {
        width: 40%;
        min-width: 200px;
        max-width: 360px;
      }

      .name-field {
        width: 40%;
        min-width: 200px;
        max-width: 300px;
      }

      .type-field {
        width: 130px;
        min-width: 130px;

        .p-dropdown {
          width: 100%;
        }
      }

      .action-field {
        width: 80px;
        min-width: 80px;
      }

      .submit-button {
        margin-right: 1rem;
        width: 100px;
      }

      .add-button {
        width: 100px;
      }

      .input-text-field-container {
        position: relative;

        .p-inputtext {
          width: 100%;
        }

        .validation-error-message {
          font-size: 0.75rem;
          position: absolute;
          bottom: -1.1rem;
          left: 0.5rem;
          color: @custom-red;
        }
      }
    }
  }
}