@import '@/styles/_variables.less';

.config-container {
  &.verkada-network-events {
    padding: 1rem;
    align-items: flex-start;

    .config-content {
      justify-content: flex-start;

      .table-container {
        display: inline-block;
        width: 100%;

        .p-accordion-header-link {
          &:focus {
            box-shadow: none;
          }
        }

        .p-accordion-header-text {
          width: 100%;

          .accordion-tab-header-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        .p-datatable-wrapper {
          .p-datatable-thead {
            .p-filter-column {
              .p-column-filter-element {
                margin-right: 5px;
              }

              .p-inputtext {
                font-size: 0.875rem;
                padding: 0.5rem;
              }

              .p-column-filter-menu-button {
                margin-left: auto;
              }

              .p-column-filter-clear-button {
                &.p-link {
                  width: 0;
                  margin: 0;

                  &.p-hidden-space {
                    width: 0;
                    margin: 0;
                  }
                }
              }
            }
          }
        }

        .p-paginator {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;

          &.p-paginator-bottom {
            .p-dropdown {
              margin-right: 0.5rem;
              height: 40px;

              .p-inputtext {
                padding: 0.375rem 0.5rem;
              }
            }

            button {
              height: 40px;
            }
          }
        }

      }
    }

  }
}