@import '@/styles/_variables.less';

#countdown {
  height: 54px;
  width: 54px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#countdown-number {
  position: absolute; /* Ensure the number is centered absolutely */
  height: 54px;
  width: 54px;
  border: none;
  border-radius: 50%;
  display: flex; /* Flexbox centering */
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: @border-color;
  z-index: 1; /* Ensure the number is above the SVG circle */
  background-color: transparent; /* Optional: If you want a background for the number */
}

.custom-countdown {
  position: fixed;
  right: 2rem;
  bottom: 0;
  bottom: 1.25rem;
  width: 54px;
  height: 54px;
  display: flex; /* Flexbox centering */
  align-items: center;
  justify-content: center;
  z-index: 2; /* Ensure the circle is above the number's border */

  svg {
    width: 54px;
    height: 54px;
    transform: rotateY(-180deg) rotateZ(-90deg);
  }

  svg circle {
    stroke-dasharray: 163.36; /* 2 * PI * 26 (radius) */
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 2px; /* Match the border width of countdown-number */
    stroke: @border-color;
    fill: none;
    animation: countdown 3s linear infinite forwards;
    z-index: 3;
  }

  @keyframes countdown {
    from {
      stroke-dashoffset: 163.36;
    }
    to {
      stroke-dashoffset: 0px;
    }
  }
}