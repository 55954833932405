@import '@/styles/_variables.less';

.duplicated-table-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    border-bottom: 1px solid @border-color;
    padding-bottom: 0.25rem;

    &:last-child {
        border-bottom: 0;
    }

    >h6 {
        flex: 1;
    }

    >div {
        flex: 2;
    }
}

.csv-data-imported {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.25rem 0;

    .csv-data-imported-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 60%;
        padding: 0.25rem 0;
        border-bottom: 1px solid @border-color;
        font-size: 1.15rem;

        &:last-child {
            border-bottom: 0;
        }

        .csv-data-imported-row-label {
            flex: 1;
            text-align: left;
        }

        .csv-data-imported-row-value {
            flex: 1;
            text-align: center;
        }
    }
}