@import '@/styles/_variables.less';

.device-location-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;
    height: 100%;

    .panel {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .title-text {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }

      .floor-list-container {
        min-width: 240px;
        width: 100%;
        max-width: 260px;

        .floor-list {
          padding: 0.5rem;
          height: auto;
          overflow: auto;
          border: 2px solid #0000005c;
          border-radius: @custom-border-radius;
          background-color: white;

          .floor-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: normal;
            text-align: left;
            padding: 0.5rem;
            font-weight: 500;
            font-size: 0.875rem;

            &.checked {
              color: @custom-selected-color;
              font-weight: bolder;
            }
          }
        }
      }

      .floor-map-container {
        width: 100%;
        min-width: 500px;

        .floor-map {
          width: 100%;
          height: auto;
          padding: 0.5rem;

          .image-marker {
            height: auto;

            .map-marker-style {
              font-weight: bold;
              font-size: 1.2rem;
              text-shadow: 2px 0 white, 0 -2px white, -2px 0 white, 0 2px white;
            }
          }
        }
      }

      .device-list-container {
        min-width: 300px;
        max-width: 360px;

        .category-dropdown-container {
          width: 100%;

          .custom-dropdown {
            width: 100%;
            margin-bottom: 0.25rem;
            padding: 0;

            .device-category-dropdown {
              &.p-dropdown {
                height: 40px;
                width: 100%;
                border: 2px solid #0000005c;
                border-radius: @custom-border-radius;

                .p-dropdown-label {
                  justify-content: center;
                  font-size: 1rem;
                }

                .p-dropdown-trigger {
                  display: flex;
                }
              }
            }
          }
        }

        .device-list {
          padding: 0;
          height: auto;
          max-height: 560px;
          overflow: auto;
          border: 2px solid #0000005c;
          border-radius: @custom-border-radius;
          overflow: auto;
          background-color: white;

          .device-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 36px;
            border-bottom: 1px solid #dddddd;

            &:last-child {
              border-bottom: none;
            }

            .device-label {
              width: calc(100% - 90px);
              height: 100%;
              text-align: left;
              display: flex;
              align-items: center;
              padding: 0.25rem 0.25rem;

              .marker-button {
                padding: 0;
                height: 32px;
                width: 40px;
                min-width: 40px;
                max-width: 40px;
              }

              .device-name-button {
                padding: 0;
                padding-left: 3px;
                padding-right: 3px;
                min-height: 32px;
                height: auto;

                .p-button-label {
                  font-size: 0.875rem;
                  font-weight: 500;
                  color: #333333;
                  text-align: left;
                }
              }
            }

            .meeting-room-container {
              min-width: 44px;
              width: 44px;
              height: 100%;
              padding: 0.25rem;
              color: lightcoral;

              &:hover {
                color: red;
              }

              .meeting-room-button {
                width: 40px;
                padding: 0;
                height: auto;

                &.has-roomname {
                  .p-button-icon {
                    color: @custom-selected-color;
                    font-weight: bolder;
                  }
                }
              }
            }

            .trash-container {
              min-width: 44px;
              width: 44px;
              height: 100%;
              cursor: pointer;
              padding: 0.25rem;
              color: lightcoral;

              &:hover {
                color: red;
              }

              .trash-button {
                width: 40px;
                padding: 0;
                height: auto;
              }
            }
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-start;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.meeting-room-setting-content {
  .shortening-method-dropdown,
  .shortening-threshold-dropdown,
  .cancel-method-dropdown,
  .cancel-threshold-dropdown {
    .p-dropdown-label.p-inputtext {
      justify-content: flex-start;
    }
  }
}
