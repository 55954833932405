@import '@/styles/_variables.less';

.network-container {
  &.network-usage {
    padding: 1rem;
    align-items: flex-start;

    .data-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;

      .data-container__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0;
        padding: 0.5rem;
        width: 50%;

        @media (max-width: 850px) {
          width: 100%;
        }

        .chart-container {
          margin-top: 0.5rem;
          width: 100%;

          .recharts-responsive-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}