@import '@/styles/_variables.less';

.network-container {
  &.wireless-latency {
    padding: 1rem;
    align-items: flex-start;

    .data-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;

      .data-container__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0;
        padding: 0.5rem;
        width: 50%;

        .chart-container {
          margin-top: 0.5rem;
          width: 100%;

          .recharts-responsive-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    @media (max-width: 1280px) {
      .data-container {
        flex-wrap: wrap;

        .data-container__item {
          width: 50%;
          padding: 0.5rem;

          .chart-container {
            margin-top: 1rem;
          }
        }
      }
    }

    @media (max-width: 850px) {
      .data-container {
        flex-wrap: wrap;

        .data-container__item {
          width: 100%;
          padding: 0;

          .chart-container {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}