@import '@/styles/_variables.less';

.config-container {
  &.people-counts {
    padding: 1rem;
    align-items: flex-start;

    .config-title-container {
      .text-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: @mobile-size) {
          flex-wrap: wrap;
        }

        .title-text {
          @media (max-width: @mobile-size) {
            white-space: nowrap;
          }
        }

        .custom-legend-button {
          padding: 0;
          margin-left: 1rem;
          margin-top: 4px;
          color: #333333;
          @media (max-width: @mobile-size) {
            width: 40px;
          }
        }

        .last-updated-ts {
          margin-left: 5rem;
          font-weight: 500;
          @media (max-width: @mobile-size) {
            flex-basis: 100%;
            margin-left: 0;
          }
        }
      }
    }

    .config-content {
      justify-content: flex-start;
    }

    .main-container {
      width: 100%;
      display: flex;
      align-items: flex-start;

      .data-container-legend-signage {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: #f5f5f5;
        border: 2px solid #555555;
        box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);
        color: #444444;
        min-width: max-content;

        .legend-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-left: 0.75rem;
          padding-right: 0.75rem;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          flex-wrap: nowrap;

          .legend-header {
            font-weight: bold;
            padding: 0.25rem;
            width: 100%;
            text-align: center;
          }

          .legend-content {
            margin-top: 0.5rem;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            flex-wrap: wrap;

            .legend-header-t {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-top: 0.125rem;
              padding-bottom: 0.125rem;
              gap: 5px;
              position: relative;
              flex-direction: column;

              .item-row {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                height: 26px;

                .item-name-container {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  width: 130px;

                  @media (max-width: @mobile-size) {
                    width: 100%;
                    padding: 0;
                  }

                  .item-color {
                    width: 20px;
                    height: 20px;
                    border: 1px solid #333333;
                  }

                  .item-name {
                    margin-left: 0.5rem;
                    font-weight: bold;
                  }

                  .menu-block1 {
                    padding: 10px 30px;
                    color: #333;
                    text-align: center;
                    font-size: 16px;
                    font-weight: bold;
                    background-color: #e9ecef;
                    clip-path: polygon(
                      35% 0,
                      50% 30%,
                      65% 0,
                      65% 100%,
                      35% 100%
                    );

                    margin-left: -7px;
                    transform-origin: center;
                  }

                  .menu-block2 {
                    padding: 10px 30px;
                    color: #333;
                    clip-path: polygon(35% 0, 65% 0, 65% 20%, 50% 50%, 35% 20%);

                    margin-left: -7px;
                  }
                }

                .item-value {
                  margin-bottom: 0.125rem;
                  font-weight: bold;
                  min-width: 36px;
                }
              }
            }

            .legend-floormap {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 180px;
              width: 160px;

              @media (max-width: 1200px) {
                margin-top: 8px;
              }

              @media (max-width: (@mobile-size)) {
                width: 50%;
                min-width: 180px;
                justify-content: center;
                margin-top: 16px;
              }

              .sample-marker {
                width: 50px;
                min-width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 1px solid #555555;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #00a86b;

                .text-count {
                  font-size: 1.85rem;
                  font-weight: bold;
                  color: white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding-bottom: 0.25rem;
                }
              }

              .floormap-text {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                margin-left: 0.75rem;
              }
            }

            .legend-snr {
              margin-top: 0.5rem;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              flex-direction: column;
              width: 100%;
              flex-wrap: wrap;
            }
          }
        }
      }

      @media (max-width: @mobile-size) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 220px);
      }

      .image-container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 65%;
        min-width: 500px;

        @media (max-width: @mobile-size) {
          width: 100%;
          padding: 0;
          margin-top: 2rem;
          min-width: auto;
          max-width: 100%;
        }

        .image-marker-tools {
          padding-top: 0;
          padding-bottom: 0;
          width: 160px;
          margin-left: auto;
        }

        .image-marker {
          img {
            height: auto;
            width: 100%;
          }

          .custom-image-marker {
            height: 70vh;
            width: auto;
          }

          .custom-marker-people-count {
            @media (max-width: @mobile-size) {
              @mobile-width: calc(100vw / 12);
              width: @mobile-width !important;
              height: @mobile-width !important;

              .text-count {
                font-size: calc(@mobile-width / 2) !important;
              }
            }
          }
        }
      }
    }
  }

  &.signage-mode {
    .main-container {
      .image-container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        min-width: 500px;
      }
    }
  }
}

.custom-legend-tooltip-people-count.p-tooltip.p-component {
  @media (max-width: @mobile-sm-size) {
    top: 110px !important;
    left: 0px !important;
    .p-tooltip-arrow {
      left: 65% !important;
      top: -2px !important;
      transform: rotate(90deg);
    }
  }
  .data-container-legend {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #f5f5f5;
    box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);
    color: #444444;

    .legend-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      flex-wrap: nowrap;

      @media (max-width: @mobile-size) {
        padding: 0;
      }

      .legend-header {
        font-weight: bold;
        padding: 0.25rem;
        width: 100%;
        text-align: center;
      }

      .legend-content {
        margin-top: 0.5rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;

        .legend-header-t {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-top: 0.125rem;
          padding-bottom: 0.125rem;
          gap: 5px;
          position: relative;
          flex-direction: column;

          .item-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 26px;

            .item-name-container {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 130px;

              @media (max-width: @mobile-size) {
                width: 100%;
                padding: 0;
              }

              .item-color {
                width: 20px;
                height: 20px;
                border: 1px solid #333333;
              }

              .item-name {
                margin-left: 0.5rem;
                font-weight: bold;
              }

              .menu-block1 {
                padding: 10px 30px;
                color: #333;
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                background-color: #e9ecef;
                clip-path: polygon(35% 0, 50% 30%, 65% 0, 65% 100%, 35% 100%);

                margin-left: -7px;
                transform-origin: center;
              }

              .menu-block2 {
                padding: 10px 30px;
                color: #333;
                clip-path: polygon(35% 0, 65% 0, 65% 20%, 50% 50%, 35% 20%);

                margin-left: -7px;
              }
            }

            .item-value {
              margin-bottom: 0.125rem;
              font-weight: bold;
              min-width: 36px;
            }
          }
        }

        .legend-floormap {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 180px;
          width: 160px;

          @media (max-width: 1200px) {
            margin-top: 8px;
          }

          @media (max-width: (@mobile-size)) {
            width: 50%;
            min-width: 180px;
            justify-content: center;
            margin-top: 16px;
          }

          .sample-marker {
            width: 50px;
            min-width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #555555;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #00a86b;

            .text-count {
              font-size: 1.85rem;
              font-weight: bold;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 0.25rem;
            }
          }

          .floormap-text {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            margin-left: 0.75rem;
          }
        }

        .legend-snr {
          margin-top: 0.5rem;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          width: 100%;
          flex-wrap: wrap;
        }
      }
    }
  }
}

.custom-legend-tooltip-signage-people-count.p-tooltip.p-component {
  top: 5px !important;
  left: 154px !important;
  .p-tooltip-arrow {
    left: 0 !important;
    top: 20% !important;
  }

  @media (max-width: @mobile-size) {
    top: 60px !important;
    left: 0px !important;
    .p-tooltip-arrow {
      left: 65% !important;
      top: -2px !important;
      transform: rotate(90deg);
    }
  }

  .data-container-legend {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #f5f5f5;
    box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);
    color: #444444;

    .legend-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      flex-wrap: nowrap;

      @media (max-width: @mobile-size) {
        padding: 0;
      }

      .legend-header {
        font-weight: bold;
        padding: 0.25rem;
        width: 100%;
        text-align: center;
      }

      .legend-content {
        margin-top: 0.5rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;

        .legend-header-t {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-top: 0.125rem;
          padding-bottom: 0.125rem;
          gap: 5px;
          position: relative;
          flex-direction: column;

          .item-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 26px;

            .item-name-container {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 130px;

              @media (max-width: @mobile-size) {
                width: 100%;
                padding: 0;
              }

              .item-color {
                width: 20px;
                height: 20px;
                border: 1px solid #333333;
              }

              .item-name {
                margin-left: 0.5rem;
                font-weight: bold;
              }

              .menu-block1 {
                padding: 10px 30px;
                color: #333;
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                background-color: #e9ecef;
                clip-path: polygon(35% 0, 50% 30%, 65% 0, 65% 100%, 35% 100%);

                margin-left: -7px;
                transform-origin: center;
              }

              .menu-block2 {
                padding: 10px 30px;
                color: #333;
                clip-path: polygon(35% 0, 65% 0, 65% 20%, 50% 50%, 35% 20%);

                margin-left: -7px;
              }
            }

            .item-value {
              margin-bottom: 0.125rem;
              font-weight: bold;
              min-width: 36px;
            }
          }
        }

        .legend-floormap {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 180px;
          width: 160px;

          @media (max-width: 1200px) {
            margin-top: 8px;
          }

          @media (max-width: (@mobile-size)) {
            width: 50%;
            min-width: 180px;
            justify-content: center;
            margin-top: 16px;
          }

          .sample-marker {
            width: 50px;
            min-width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #555555;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #00a86b;

            .text-count {
              font-size: 1.85rem;
              font-weight: bold;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 0.25rem;
            }
          }

          .floormap-text {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            margin-left: 0.75rem;
          }
        }

        .legend-snr {
          margin-top: 0.5rem;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          width: 100%;
          flex-wrap: wrap;
        }
      }
    }
  }
}
