@import '@/styles/_variables.less';

.tenant-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;

    .main-row-container {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      margin-bottom: 1.25rem;
      min-width: 1024px;

      .reset-button {
        margin-left: 1rem;
        margin-right: 1rem;
        width: 120px;
      }

      .submit-button {
        margin-left: 1rem;
        margin-right: 1rem;
        width: 100px;
      }

      .add-button {
        width: 100px;
      }

      .left-title {
        width: 60px;
        text-align: left;
        margin-right: 20px;
        font-weight: 600;
        font-size: 1rem;
      }

      .right-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;

        .row-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 1.5rem;
          flex-wrap: wrap;
          min-height: 46px;

          .product-field {
            width: 165px;
            text-align: left;
          }

          .key-field {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 1.75rem 1rem;
          }

          &.is-header {
            margin-bottom: 0.5rem;

            .product-field {
              font-weight: 600;
              font-size: 0.875rem;
            }

            .key-field {
              font-weight: 600;
              font-size: 0.875rem;
            }
          }
        }
      }

      .input-text-field-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .p-inputtext {
          width: 220px;
        }

        &.teamsWebhookUrl {
          .p-inputtext {
            width: calc(440px + 1rem);
          }
        }

        &.microsoft-graph-info {
          margin-left: 1rem;

          .p-inputtext {
            width: 180px;
          }
        }

        .input-top-title {
          font-size: 0.75rem;
          position: absolute;
          top: -1.2rem;
          left: 0rem;
          color: #333333;
          font-weight: 600;
        }

        .validation-error-message {
          font-size: 0.75rem;
          position: absolute;
          top: -1.1rem;
          right: 0.25rem;
          color: @custom-red;
          font-weight: bold;
          background-color: white;
        }
      }

      &.schedule-tool,
      &.shortening-meeting,
      &.tenant-name,
      &.room-notice,
      &.auth-provider,
      &.sign-on {
        .left-title {
          width: 225px;
        }

        .right-content {
          .input-text-field-container {
            .p-inputtext {
              width: 340px;
            }

            .p-dropdown-label {
              width: 180px;
              justify-content: flex-start;
            }
          }
        }
      }

      &.shortening-meeting {
        .right-content {
          .input-text-field-container {
            .p-dropdown {
              width: 100px;
            }
          }
        }
      }

      @media (max-width: 1100px) {
        flex-direction: column;
        min-width: auto;
      }

      &.auth-provider-header {
        .left-title {
          width: 100%;
        }
      }

      &.auth-provider-container {
        .right-content {
          .row-container {
            .product-field {
              width: 280px;
            }

            .key-field {
              .input-text-field-container {
                width: 280px;

                .p-dropdown {
                  width: 100%;
                }

                .p-password {
                  width: 100%;
                }

                .p-inputtext {
                  width: 100%;
                  justify-content: flex-start;
                }
              }
            }
          }
        }
      }
    }

    .fade-enter {
      opacity: 0;
    }

    .fade-enter-active {
      opacity: 1;
      transition: opacity 300ms;
    }

    .fade-exit {
      opacity: 1;
    }

    .fade-exit-active {
      opacity: 0;
      transition: opacity 300ms;
    }
  }
}
