@import '@/styles/_variables.less';

.config-container {
  &.configuration-page {
    align-items: flex-start;

    .config-content {
      justify-content: flex-start;
      padding-top: 1rem;

      .config-button-item-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-left: 1rem;

        .config-button-item {
          margin-top: 0.35rem;
          margin-bottom: 0.35rem;

          .p-button {
            font-size: 1.5rem;
            font-weight: 700;
            color: @custom-selected-color;

            &:hover {
              background-color: white;
              text-decoration: underline;
            }
          }
        }
      }

      .config-content-container {
        width: 100%;
        margin-bottom: 0.5rem;

        .p-accordion-header-link {
          &:focus {
            box-shadow: none;
          }
        }

        .p-accordion-header-text {
          width: 100%;

          .accordion-tab-header-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
}