// Variables //

@root-background : linear-gradient(90deg, rgba(35,35,72,1) 0%, rgba(85,85,174,1) 100%);


@header-right : #5b6be8;
@header-side-padding : 30px;
@header-background : linear-gradient(90deg, rgba(35,35,72,1) 20%, rgba(85,85,174,1) 100%);
@header-height : 59px;

@sidebar-width: 205px;
@dynamic-parameter-width: 180px;

@custom-black-color: #333333;
@custom-red: #F44336;
@custom-red-alpha-8: rgba(244, 67, 54, 0.8);
@custom-red-alpha-4: rgba(244, 67, 54, 0.4);
@custom-green: rgba(0, 168, 107, 1);
@custom-selected-color: #00B2FF;
@custom-yellow: #FFD721;
@custom-yellow-opacity-07: rgba(255, 215, 33, 0.7);
@custom-border-radius: 4px;
@underline-text-color: @custom-selected-color;

@mobile-size: 811px;
@mobile-sm-size: 410px;

@config-content-bg-color: rgba(255, 255, 255, 1.0);

@primary-color : #00CCF9;
@secondary-color : #10b6e9;
@third-color : #0089C0;
@danger-color : #DC2EB7;
@danger-color-bis : #e93c58;
@warning-color : #f5b225;
@warning-color-bis : #f4a90d;
@success-color : #80C535;
@light-color : #ced4da;
@white : #fff;
@dark-color : #333;
@black : #262626;
@dark-color : #3E4550;
@grey : #97A7B7;
@grey-light : rgba(151, 167, 183, 0.21);
@green : #3FE5CA;
@red : #F62172;
@error : #FF6F90;
@blue : #00CCF9;
@rhino-blue : #283F53;
@turquoise-blue : #4EDCAD;
@cerulean-blue : #00AFED;
@mantle-grey : #415467;

@text-primary-color : #000;
@text-secondary-color : #97A7B7;
@title-primary-color : #3E4550;
@text-white-color: #ffffff;

@background-body : #fff;
@sidebar-background: linear-gradient(90deg, rgba(29,29,45,1) 0%, rgba(35,35,72,1) 100%);
@login-background : @dark-color;
@panel-background : @white;
@border-color : @light-color;
@loader-color : grey;
@panel-background :@white;

@graphColor1 : @primary-color;
@graphColor2 : @secondary-color;
@graphColor3 : @warning-color;
@graphColor4 : #2d3b48;
@graphColor5 : @success-color;
@graphColor6 : @danger-color;
@graphColor7 : @light-color;



.sidebar-gradient () {
  background: rgb(65, 45, 98);
  background: -moz-linear-gradient(top, rgba(25, 57, 63, 1) 0%, rgba(38, 43, 70, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(25, 57, 63, 1) 0%, rgba(38, 43, 70, 1) 100%);
  background: linear-gradient(to bottom, rgba(25, 57, 63, 1) 0%, rgba(38, 43, 70, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0D1928', endColorstr='#262b46', GradientType=0);
}

.font-smoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}