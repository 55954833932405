@import '@/styles/_variables.less';

.config-container {
  &.employee-locations {
    padding: 1rem;
    align-items: flex-start;

    .config-content {
      justify-content: flex-start;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .select-component {
      width: 100%;
      padding: 0.25rem 0.125rem;
      @media (max-width: @mobile-size) {
        padding: 0.125rem;
      }
    }

    .left-right-component-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0.5rem;
      @media (max-width: @mobile-size) {
        overflow: scroll;
      }
    }
  }
}
