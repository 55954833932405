@import '@/styles/_variables.less';

.employee-setting-info-dialog {
    .employee-info-container {
        .employee-loading-container {}

        .main-row-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            margin-bottom: 1.5rem;
            width: 100%;

            .title {
                width: 160px;
                min-width: 160px;
                text-align: left;
            }

            .input-text-field-container {
                width: calc(100% - 120px);
                position: relative;

                .p-dropdown-label {
                    justify-content: flex-start;
                }

                .validation-error-message {
                    font-size: 0.75rem;
                    position: absolute;
                    bottom: -1.1rem;
                    left: 0.5rem;
                    color: @custom-red;
                }

                &.empl-image-field {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 1rem;

                    .empl-image-preview-field {
                        min-width: 60%;
                        border: 2px solid #0000005C;
                        border-radius: @custom-border-radius;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #0000005C;
                        background-color: white;
                    }

                    .p-fileupload {
                        display: flex;
                        flex-direction: row-reverse;
                        padding: 0;
                        height: 38px;
                        font-size: 0.9rem;
                        border: 2px solid #0000005C;
                        border-radius: @custom-border-radius;
                        justify-content: center;

                        .p-fileupload-buttonbar {
                            padding: 0;
                            display: flex;
                            flex-wrap: nowrap;
                            border: 0;
                            background: transparent;
                            gap: 0;
                            width: 100px;
                            margin-left: auto;
                            margin-right: auto;
                            background-color: white;

                            .p-button {
                                border: 0;
                                background: transparent;
                                color: @custom-selected-color;
                                width: 100%;
                                height: 100%;
                                margin-right: auto;
                                margin-left: auto;
                            }

                        }

                        .p-fileupload-content {
                            padding: 0;
                            border: 0;
                            background: transparent;
                            width: 100%;
                            display: none;

                            .p-progressbar {
                                display: none;
                            }

                            .p-fileupload-row {
                                .item-template {
                                    height: 100%;
                                    padding: 0 0.25rem;
                                    font-weight: 600;
                                }
                            }

                            .p-message {
                                height: 100%;
                                border-radius: 0;
                                margin: 0;

                                .p-message-wrapper {
                                    padding: 0;
                                    height: 34px;
                                    font-size: 0.875rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}