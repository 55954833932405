.custom-calendar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
  background-color: transparent;

  .title {
    font-size: 0.875rem;
    padding: 0;
    margin-bottom: -4px;
    z-index: 2;
    color: #333333;
  }

  .p-calendar.custom-date-picker {
    border: 0;

    .p-datepicker-trigger {
      display: none;
    }

    &.p-inputwrapper {
      height: 32px;
      width: 120px;
      border: 0;
      border-bottom: 1px solid #dddddd;
      border-radius: 0;

      .p-inputtext {
        box-shadow: none;
        justify-content: flex-start;
        padding: 0;
        border: 0;
        font-weight: 700;
        border-radius: 0;
        background-color: transparent;
        cursor: pointer;
        height: 32px;
      }
    }
  }
}