@import '@/styles/_variables.less';

.employee-location-list-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 4px;
  padding-top: 4px;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 1rem;

  @media (max-width: @mobile-size) {
    flex-wrap: wrap-reverse;
  }

  .component-container {
    width: 50%;
    padding: 0.5rem;

    @media (max-width: @mobile-size) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      padding: 0.25rem;
    }
  }
}
