@import '@/styles/_variables.less';

.config-container {
  &.air-quality {
    padding: 1rem;
    align-items: flex-start;

    .config-content {
      justify-content: flex-start;

      .p-tabview {
        width: 100%;

        .p-tabview-nav-content {
          .p-tabview-nav{
            @media (max-width: 850px) {
              justify-content: center;
            }
          }

          button {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }
        }

        .p-tabview-panels {
          padding-top: 0;

          @media (max-width: 850px) {
            padding: 0;
          }
        }
      }
    }
  }
}