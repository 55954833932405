@import '@/styles/_variables.less';

.people-count-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;

    .people-count-format-row-container {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      margin-bottom: 50px;
      min-width: 1024px;

      .input-text-field-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .p-inputtext {
          width: 220px;
        }

        .input-top-title {
          font-size: 0.75rem;
          position: absolute;
          top: -1.2rem;
          left: 0rem;
          color: #333333;
          font-weight: 600;
        }

        .validation-error-message {
          font-size: 0.75rem;
          position: absolute;
          top: 3rem;
          right: 3.25rem;
          color: @custom-red;
          font-weight: bold;
          background-color: white;
        }
      }

      &.people-count-format {
        .left-title {
          width: 180px;
          text-align: left;
          margin-right: 20px;
          font-weight: 600;
          font-size: 1rem;
        }

        .right-content {
          .input-text-field-container {
            .p-inputtext {
              width: 340px;
            }

            .p-dropdown-label {
              width: 150px;
              justify-content: flex-start;
            }

            .p-dropdown {
              width: 150px;
            }
          }
        }
      }

      @media (max-width: 1100px) {
        flex-direction: column;
        min-width: auto;
      }
    }

    .rate-setting-container {
      width: 100%;
      min-width: 500px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .title-text {
        text-align: left;
      }

      .rate-setting-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .rate-setting-label {
          min-width: 100px;
          width: 100px;
          text-align: left;
          font-weight: 600;
          font-size: 1rem;
        }

        .rate-setting-content {
          display: flex;
          width: calc(100% - 100px);
          align-items: center;
          justify-content: flex-start;

          .rate-setting-content-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;

            .label {
              font-size: 0.875rem;
              text-align: left;
              width: 60px;
              min-height: 24px;
              position: relative;

              .error-label {
                color: @custom-red;
                position: absolute;
                left: 2rem;
                white-space: nowrap;
                font-weight: 500;
                background-color: white;
                z-index: 100;
              }

              &-left {
                left: 50%;
              }

              &-right {
                right: 50%;
              }
            }

            .value-input {
              min-height: 40px;
              z-index: 2;

              .p-inputnumber-input {
                width: 60px;
                max-width: 60px;
                text-align: center;

                &.p-invalid {
                  border-width: 2px;
                }
              }

              &-left {
                margin-left: 120px;
              }

              &-right {
                margin-right: 120px;
              }
            }

            .color-line {
              margin-top: 5px;
              height: 10px;
              min-height: 10px;
              width: 100%;
            }
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-start;
      margin-top: 180px;
      margin-bottom: 10px;
    }

    .submit-button {
      width: 100px;
    }
  }
}
