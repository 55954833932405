@import '@/styles/_variables.less';

.workinghour-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;

    .main-row-container {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      margin-bottom: 1.5rem;
      min-height: 40px;

      .reset-button {
        margin-left: 1rem;
        margin-right: 1rem;
        width: 120px;
      }

      .submit-button {
        margin-left: 1rem;
        margin-right: 1rem;
        width: 100px;
      }

      .add-button {
        width: 100px;
      }

      .left-title {
        width: 120px;
        text-align: left;
        margin-right: 20px;
        font-weight: 600;
        font-size: 1rem;
        display: flex;
        align-items: center;
      }

      .right-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: center;

        .row-container {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin-bottom: 1.5rem;
          flex-wrap: wrap;
          width: 100%;
          max-width: 1024px;

          .product-field {
            width: 50%;
            min-width: 200px;
            max-width: 460px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .is-header {
              font-weight: 600;
              font-size: 0.875rem;
            }
          }

          .key-field {
            width: 50%;
            min-width: 200px;
            max-width: 460px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .is-header {
              font-weight: 600;
              font-size: 0.875rem;
            }
          }

          .p-calendar {
            .p-inputtext {
              border-radius: 0;
              border: 0;
              border-bottom: 2px solid #0000005C;
            }

            .p-button-icon-only {
              background: transparent;
              border: none;
              color: @custom-selected-color;
              padding: 0;
              height: 36px;

              .p-button-icon {
                font-size: 1.5rem;
              }
            }
          }

          .daylist-container {
            margin-top: 1rem;
            border: 0;
            border-radius: @custom-border-radius;
            min-height: 190px;
            max-height: 190px;
            width: 270px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .day-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              padding-left: 1rem;
              padding-right: 1rem;
              border-bottom: 1px solid #dddddd;

              &.no-data {
                color: #555555;
                justify-content: center;
                font-size: 0.875rem;
              }

              &:last-child {
                border-bottom: 0;
              }

              .p-button {
                height: 37px;
              }
            }
          }
        }
      }

      .input-text-field-container {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        &.workinghour-group {
          .hour-container {
            width: 100px;
          }

          .minute-container {
            width: 100px;
          }
        }

        &.checkbox-group {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 1rem;

          .checkbox-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            label {
              font-weight: 500;
            }
          }
        }

        .input-top-title {
          font-size: 0.75rem;
          position: absolute;
          top: -1.4rem;
          left: 0rem;
          color: #333333;
          font-weight: 600;
        }

        .validation-error-message {
          font-size: 0.75rem;
          position: absolute;
          bottom: -1.1rem;
          left: 0.5rem;
          color: @custom-red;
        }
      }
    }
  }
}