@import '@/styles/_variables.less';

.config-container {
  &.employee-access-log-container {
    height: auto;
    display: flex;
    overflow: auto;

    .config-title-container {
      height: 60px;

      .button-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .button-menulist-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 2rem;

      .pc-mobile-toggle-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        margin-left: auto;
        margin-right: auto;

        .pc-toggle-container,
        .mobile-toggle-container {
          display: flex;
          align-items: center;
          justify-content: center;

          label {
            margin-left: 0.25rem;
            margin-right: 0.25rem;
            font-weight: 500;
          }
        }

        .pc-toggle-container {}

        .mobile-toggle-container {}
      }

      .slider-container {
        width: 300px;
        margin-left: 2rem;
        margin-right: 5rem;
        position: relative;

        .time-slider-tooltip-start {
          position: absolute;
          top: 1rem;
          background-color: rgba(51, 51, 51, 0.3);
          padding: 2px 4px;
          border-radius: 3px;
          font-size: 0.9rem;
        }

        .time-slider-tooltip-end {
          position: absolute;
          top: -2rem;
          background-color: rgba(51, 51, 51, 0.3);
          padding: 2px 4px;
          border-radius: 3px;
          font-size: 0.9rem;
        }
      }
    }

    .config-content {
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      overflow: auto;
      padding: 0;
      flex-direction: column;

      .search-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 44px;
        padding: 0.325rem 1rem;
        background-color: #eeeeee;
        border-radius: @custom-border-radius;
        border: 1px solid #dddddd;

        .input-container {
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          .department-search,
          .team-search,
          .employee-search {
            width: 30%;
            padding: 0.25rem 0.5rem;
            border-radius: @custom-border-radius;
            font-size: 0.8rem;
            border: 1px solid #d4d4d8;
            height: 34px;
          }
        }
      }

      .p-accordion {
        .p-accordion-tab {
          .p-accordion-header {
            height: 34px;

            .p-accordion-header-text {
              .header-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .title-container {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-left: 0.5rem;

                  &.is-selected {
                    color: @custom-selected-color;
                  }

                  .count-title {
                    margin-left: 1rem;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: #eeeeee;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    font-size: 0.85rem;
                    border: 1px solid #dddddd;
                    line-height: 24px;
                  }
                }
              }
            }

            &.p-highlight {
              .p-accordion-header-link {
                font-weight: bolder;
                color: @custom-selected-color;
              }
            }

            .p-accordion-header-link {
              padding: 0.325rem;
              padding-right: 1rem;
              padding-left: 1rem;
              border-radius: 0.375rem;
              font-weight: normal;
              font-size: 0.9rem;
              height: 100%;
              flex-direction: row-reverse;
              justify-content: space-between;

              &:focus {
                box-shadow: none;
              }
            }
          }

          .p-toggleable-content {
            .p-accordion-content {
              padding: 0.25rem;
              padding-right: 0;
              padding-left: 1rem;
              border: 0;
            }
          }

          .employee-container {
            .p-toggleable-content {
              .p-accordion-content {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                background-color: #f5deb33b;
                box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.4);

                .verkada-logs-container {
                  position: relative;
                  width: 100%;

                  .verkada-row {
                    height: 40px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid #dddddd;
                    border-radius: @custom-border-radius;

                    .verkada-row-title {
                      width: 160px;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      padding-left: 0.5rem;
                      padding-right: 0.5rem;
                      font-size: 0.85rem;
                      background-color: white;
                      border-top-left-radius: 3px;
                      border-bottom-left-radius: 3px;
                    }

                    .verkada-row-content {
                      background-color: #eeeeee;
                      position: relative;
                      height: 100%;
                      width: 100%;
                      border-top-right-radius: 3px;
                      border-bottom-right-radius: 3px;

                      .verkada-in-out-div {
                        position: absolute;
                        width: 6px;
                        top: 0;
                        bottom: 0;
                      }
                    }
                  }
                }

                .meraki-logs-container {
                  position: relative;
                  width: 100%;

                  .meraki-row {
                    height: 40px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid #dddddd;
                    border-radius: @custom-border-radius;
                    margin-top: 0.25rem;

                    .meraki-row-title {
                      width: 160px;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      padding-left: 0.5rem;
                      padding-right: 0.5rem;
                      font-size: 0.85rem;
                      background-color: white;
                      border-top-left-radius: 3px;
                      border-bottom-left-radius: 3px;
                    }

                    .meraki-row-content {
                      background-color: #eeeeee;
                      position: relative;
                      height: 100%;
                      width: 100%;
                      border-top-right-radius: 3px;
                      border-bottom-right-radius: 3px;
                      overflow: hidden;

                      .meraki-in-out-div {
                        position: absolute;
                        width: 6px;
                        min-width: 3px;
                        top: 0;
                        bottom: 0;
                        background-color: rgba(79, 113, 190, 0.6);
                        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.7);
                      }
                    }
                  }
                }

                .secureconnect-container {
                  position: relative;
                  width: 100%;

                  .secureconnect-row {
                    height: 40px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid #dddddd;
                    border-radius: @custom-border-radius;
                    margin-top: 0.25rem;

                    .secureconnect-row-title {
                      width: 160px;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      padding-left: 0.5rem;
                      padding-right: 0.5rem;
                      font-size: 0.85rem;
                      background-color: white;
                      border-top-left-radius: 3px;
                      border-bottom-left-radius: 3px;
                    }

                    .secureconnect-row-content {
                      background-color: #eeeeee;
                      position: relative;
                      height: 100%;
                      width: 100%;
                      border-top-right-radius: 3px;
                      border-bottom-right-radius: 3px;
                      overflow: hidden;

                      .secureconnect-in-out-div {
                        position: absolute;
                        width: 6px;
                        min-width: 3px;
                        top: 0;
                        bottom: 0;
                        background-color: rgba(79, 113, 190, 0.6);
                        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.7);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
