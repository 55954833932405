@import './_variables.less';

* {
  box-sizing: border-box;
  font-family: 'Noto Sans JP', 'Noto Serif', sans-serif, serif;
  .font-smoothing();
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 10px 0 5px 0;
}

h1 {
  padding: 0 10px;
  font-size: 40px;
  font-weight: 500;
}

h2 {
  font-size: 32px;
  font-weight: 500;
  color: #343a40;
}

h3 {
  font-size: 28px;
  font-weight: 500;
  color: #343a40;
}

h4 {
  font-size: 24px;
  font-weight: 500;
  color: #343a40;
}

h5 {
  font-size: 20px;
  font-weight: 500;
  color: #343a40;
}

h6 {
  font-size: 16px;
  font-weight: 500;
  color: #343a40;
}

p {
  font-size: 11px;
  font-weight: 400;
}

blockquote {
  font-size: 16.8px;
  font-weight: 400;
}

.no-margin {
  margin: 0;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.graph-title {
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
  font-weight: 200;
  color: #8497b0;
  -webkit-font-smoothing: antialiased;
}

.menu {
  z-index: 10;
  background: @root-background;
  min-height: 99vh;
  max-height: 100vh;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .link-text {
    margin-right: 10px;
    display: block;

    a.nav-link {
      display: block;
      padding: 13px 0 13px 64px;
      background-position: left center;
      background-repeat: no-repeat;
      background-size: auto 45px;
      position: relative;
      top: 50%;
      transform: translateY(25%);

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .fp-link {
    text-decoration: none;
    color: grey;
    font-family: "Source Sans Pro";
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 23px;
    text-align: left;
    cursor: pointer;
    color: #B0B0B0;

    .sidebar-container {
      margin-top: 0.5rem;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-transform: uppercase;
      padding: 0.25rem 0.5rem;
      font-size: 1rem;
      align-items: center;
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      span {
        &:hover {
          color: white;
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }

    &.active {
      .sidebar-container {
        background-color: rgba(255, 255, 255, 0.2);
        span {
          &:hover {
            color: @primary-color;
          }
        }
      }
    }

    &.active .link-text {
      color: @primary-color;
    }

    &.disabled .link-text {
      color: grey;
    }

    div {
      height: 100%;

      .link-text {
        display: block;
        height: 100%;
        padding: 15px;
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
        width: 100%;
        margin: 0;
      }

      i {
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        font-size: 18px;
        height: 28px;
        width: 28px;
        text-align: center;
        line-height: 28px;
        position: absolute;
        color: white;
      }
    }

    .fp-export {
      cursor: pointer;
    }

    &.hidden-router-link {
      .sidebar-container {
        box-shadow: none;
        background-color: transparent;
        color: transparent;
      }
    }
  }
}

html {
  scrollbar-width: none;
}

body {
  background: @background-body;

  .login-logo {
    opacity: 0;
  }

  .fp-client-authority-manager.signout {
    .header-login {
      .dots {
        opacity: 0;
      }
    }
  }

  .fp-client-authority-manager-container {
    .fp-client-authority-manager {
      @media (max-width: 768px) {
        padding: 2rem;
      }

      .standard-login {
        .login-screen {
          .forgot-password-link-container {
            .forgot-password-link {
              &:hover {
                color: #333333 !important;
                font-weight: 500;
              }
            }
          }

          .return-button {
            &:hover {
              color: #333333 !important;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .fp-logout {
    button {
      &.btn.reconnect-button {
        width: auto;
      }
    }
  }
}

.fp-loader-spinner .fp-loader-spinner_bar {
  background-color: @loader-color;
}

.root-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: @root-background;

  &.not-logged {
    background: @login-background;
  }
}

.no-scroll .grid-layout-inner {
  overflow: hidden !important;
}

.footer {
  text-align: center;
  font-size: 11px;

  a {
    color: #888;
    text-decoration: none;
  }

  img {
    height: 15px;
    padding-left: 3px;
  }
}

.page {
  z-index: 1;
  position: absolute;
  left: @sidebar-width;
  bottom: 0;
  top: @header-height;
  right: 0;
  background: @root-background;
  transition: left 0.3s ease-in-out;

  .fp-dashboard {
    width: 100%;
    height: auto;

    .grid-layout {
      overflow: auto;
      padding: 1rem;

      .grid-layout-inner {
        overflow: visible !important;

        >div {
          position: unset !important;
          width: 100%;
          height: 100% !important;
        }
      }
    }
  }

  &.page--sidebar-hidden {
    left: 0;
  }

  &.page-mobile {
    left: 0;

    .fp-dashboard {
      .grid-layout {
        padding: 0.5rem;
      }
    }
  }
}

.fp-core-ui-panel {
  height: 100%;
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1);
  background: @panel-background;
  border-radius: 10px;
  overflow: auto;

  .grid-layout {
    height: 100%;
    padding: 15px;

    &.highlight {
      background: rgb(108, 61, 141);
      background: -moz-linear-gradient(-45deg,
          rgba(25, 57, 63, 1) 0%,
          rgba(34, 75, 107, 1) 30%,
          rgba(1, 176, 238, 1) 100%);
      background: -webkit-linear-gradient(-45deg,
          rgba(25, 57, 63, 1) 0%,
          rgba(34, 75, 107, 1) 30%,
          rgba(1, 176, 238, 1) 100%);
      background: linear-gradient(135deg,
          rgba(25, 57, 63, 1) 0%,
          rgba(34, 75, 107, 1) 30%,
          rgba(1, 176, 238, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6c3d8d', endColorstr='#01b0ee', GradientType=1);
      color: #fff;

      .chart-html>div {
        background: transparent;
      }

      .card-title .subtitle {
        color: #9dc3e6;
      }

      .chart-html i {
        color: #99c1e2;
      }

      .value {
        color: #fff;
      }

      .sub-value {
        color: #99c1e2;
      }
    }
  }
}

.fp-chart,
.fp-chart-content {
  height: 100%;
}

.fp-error-query {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;

  .refresh {
    display: block;
    cursor: pointer;

    i {
      display: block;
      font-size: 30px;
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  position: relative;
  background: @header-background;
  height: @header-height + 4;
  padding: 0px 0px;

  &.header-mobile {
    background-color: @header-background;
  }

  &.header--sidebar-hidden {
    background-color: @header-background;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .fp-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .container-inner {
        display: flex;

        .menu-lists {
          display: flex;
        }
      }

      &.user {
        display: flex;
        justify-content: flex-end;

        .container-inner {
          margin-right: @header-side-padding;
        }
      }

      &.hideshowsidebar {
        transition: margin-left 0.3s ease-in-out;
        margin-left: calc(@sidebar-width + 10px);
        width: auto;

        .toggle-button-container {
          display: none;
        }

        &.hideshowsidebar-sidebar-hidden {
          transform: translateX(0);
          margin-left: 0px;

          .toggle-button-container {
            display: flex;

            .p-button {
              color: white;
            }
          }
        }
      }
    }
  }
}

.logo-container ul {
  display: flex;
  justify-content: center;
}

.logo .fp-image {
  height: 24px;
  width: auto;
}

.header .container .fp-container.tabs {
  align-items: flex-end;

  .fp-link {
    color: grey;
    font-family: "Source Sans Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;

    div {
      padding: 30px 40px 3px 40px;
      display: flex;
      align-items: flex-end;
      height: unset;

      i {
        color: grey;
        position: relative;
        font-size: 18px;
        line-height: 23px;
        height: auto;
        width: auto;
      }

      .link-text {
        padding: 0px 5px;
        font-weight: 500;
      }
    }

    &.active div {
      border-bottom: 2px solid @primary-color;
    }

    &.active .link-text {
      color: @primary-color;
    }

    &.active i {
      color: @primary-color;
    }
  }
}

.sidebar {
  display: flex;
  width: fit-content;
  background: @root-background;
  flex: 1 1 auto;
  height: calc(100vh + @header-height);
  margin-top: -@header-height;
  overflow: hidden;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
  z-index: 2;

  &:hover {
    overflow-y: auto;
  }

  &.sidebar--hidden {
    transform: translateX(-100%);
  }

  .container {
    display: flex;
    flex-direction: column;
    background: @sidebar-background;
    color: white;
    height: 100%;

    .container-inner {
      width: @sidebar-width;
      padding: 5px;
      background: @sidebar-background;
    }

    .fp-container {
      display: flex;

      &.footer-component {
        margin-top: auto;
      }
    }
  }

  &.sidebar-mobile {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 100;
    border-right: 1px solid #dddddd;
  }
}

.footer {
  flex: 1;
  align-items: flex-end;
  flex: 1 1 auto;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

.dyn-title {
  padding-top: 5px;
  padding-left: 5px;
  color: @title-primary-color;
  font-family: "Source Sans Pro";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.58px;
  line-height: 19px;
}

.chart-title {
  color: @title-primary-color;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}

.panel-title {
  color: @title-primary-color;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}

.panel-description {
  color: @title-primary-color;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}

.fp-tabs {
  position: absolute;
  overflow: visible;

  .fp-tabs-content {
    position: absolute;
    height: ~"calc(100% - 30px)";
    box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1);
    background: @panel-background;
    border-radius: 10px;
    padding: 10px;
    overflow: auto;
    top: 30px;
    left: 0px;
    right: 0px;
    bottom: 15px;
  }

  .tabs-header {
    position: relative;
    z-index: 100;
  }

  .fp-tabs-heads {
    background: transparent;
    position: absolute;
    padding: 0;
    margin: 0px 3px;
    border-top: 2px solid #f2f2f2;
    border-right: 2px solid #f2f2f2;
    z-index: 100;
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;

    .fp-tabs-head {
      display: inline-block;
      list-style: none;
      color: white;
      cursor: pointer;
      height: 28px;
      line-height: 16px;
      padding: 4px 20px 0px 20px;
      margin: 0px 0px 0px -1px;
      background-color: @white;
      text-align: center;

      span,
      .fp-tabs-head-editor {
        margin-bottom: 5px;
        bottom: 1px;
        color: grey;
        font-weight: 400;
        text-transform: uppercase;

        input {
          padding: 0;
        }
      }

      &:hover {
        background-color: #f2f2f2;

        span,
        .fp-tabs-head-editor {
          opacity: 0.7;
          color: black;
        }
      }

      &.active {
        border-bottom: 2px solid @primary-color;

        span,
        .fp-tabs-head-editor {
          opacity: 1;
          color: @primary-color;
        }
      }
    }
  }
}

.recharts-legend-wrapper {
  overflow: scroll;
}

@import "./parameters.less";