@import '@/styles/_variables.less';

.right-container {
  padding: 0.25rem;
  align-items: flex-start;

  .no-select-data {
    max-width: 300px;
  }

  .search-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 44px;
    padding: 5px 12px;
    background-color: #eeeeee;
    border-radius: 4px;
    border: 1px solid @border-color;

    .input-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .department-search,
      .team-search {
        width: 40%;
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        font-size: 0.8rem;
        border: 1px solid @border-color;
        height: 32px;
      }
    }

    .employee-search {
      width: 100%;
      border-radius: 4px;
      font-size: 0.8rem;
      border: 1px solid @border-color;
      height: 32px;
      padding-right: 35px;
    }

    .icon-clear {
      cursor: pointer;
    }
  }

  .employee-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1rem;
    height: calc(100vh - 360px);
    overflow: auto;
    display: block;

    form {
      width: 100%;
    }

    @media (max-width: @mobile-size) {
      font-size: 0.85rem;
      height: auto;
      overflow: hidden;
    }

    .member-group-container {
      width: 100%;
      height: 76px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.5rem 0.25rem;
      border: 1px solid @border-color;
      border-radius: @custom-border-radius;
      background: @background-body;

      .employee-image {
        display: flex;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid @border-color;
          background-color: @white;
        }
      }

      .basic-info-container {
        width: 50%;
        max-width: 50%;
        padding: 0 0.5rem;
      }

      .employee-name {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        font-weight: bold;
        text-align: left;
        color: #1a1a1c;
        cursor: pointer;
        padding: 0;
        max-width: 90px;
        white-space: nowrap;

        .employee-name-reading {
          font-size: 0.75rem;
          font-weight: 400;
        }

        &:hover {
          color: @custom-selected-color;
          font-weight: bold;
          text-decoration: underline;
        }

        &.is-selected {
          color: @custom-selected-color;
        }
      }

      .employee-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;

        .status {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0;
        }

        .presence-status-color {
          width: 12px;
          height: 12px;
          background-color: white;
          border-radius: 50%;
          margin-left: 14px;
          margin-right: 4px;
          margin-top: 2px;
        }

        .employee-presence-status {
          padding: 0;

          span {
            font-size: 1em;
            padding: 2px 4px;
            border-radius: @custom-border-radius;
            background-color: gray;
            color: white;

            &.presence {
              background-color: @custom-green;
            }

            &.not-presence {
              background-color: @custom-red;
            }
          }
        }
      }

      @media (max-width: @mobile-size) {
        padding-right: 0.5rem;
        .employee-image {
          min-width: 50px;
          padding: 0.25rem;
          max-width: 50px;
        }

        .employee-name {
          flex: 1;
          max-width: none;
          font-size: 0.8rem;
        }

        .basic-info-container {
          display: none;
        }

        .employee-status {
          flex: 1;
          justify-content: space-between;
          gap: 1rem;
          max-width: 150px;
          min-width: 150px;
        }
      }
    }

    .basic-container {
      background-color: @white;
      width: 100%;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 1.25em;
      color: #1a1a1c;
      font-size: 14px;
      font-weight: 500;
      line-height: 23.8px;
      letter-spacing: 0.07em;
      text-align: left;

      .copy-button-container {
        margin-left: 0.25em;
        width: 37px;
        height: 31px;
        margin-top: -0.25rem;

        .copy-button {
          padding: 0;
          height: 30px;

          .p-button-icon {
            font-size: 1.25em;
          }
        }
      }

      .left-title {
        margin-right: 0.25rem;
      }

      .right-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .info-container {
      background-color: @white;
      padding: 0.75rem 1em;
      width: 100%;
      margin-top: 0.5em;
      margin-bottom: 0.5em;

      @media (max-width: @mobile-size) {
        padding: 0.5em 0.75em;
      }
    }
  }
}
