@import '@/styles/_variables.less';

.floor-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;

    .main-content {
      max-height: calc(100vh - 300px);
      overflow: auto;
      max-width: 1280px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: @mobile-size) {
        min-width: 1280px;
      }

      .main-row-container {
        border-bottom: 1px solid #dddddd;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .main-row-container {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      margin-bottom: 1.5rem;
      max-width: 1280px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 0px 0.5rem;
      padding-bottom: 0;

      @media (max-width: @mobile-size) {
        min-width: 1280px;
      }

      &.is-header {
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
        color: #1a1a1c;
        font-weight: 600;
        text-align: center;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
        padding-bottom: 1rem;
        border-bottom: 0;
        align-items: center;

        .floorlist-field {
          flex-direction: row;

          .floor-order-field {
            width: 120px;
            min-width: 120px;
            text-align: center;
          }

          .floor-name-field {
            width: 25%;
            min-width: 180px;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
          }

          .floor-related-field {
            min-width: 250px;
          }

          .floor-map-field {
            width: 260px;
            min-width: 260px;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
          }

          .floor-scale-field {
            width: 100px;
            min-width: 100px;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
          }

          .floor-action-field {
            width: 60px;
            min-width: 60px;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
          }
        }
      }

      &.is-add-new-button-container {
        margin-top: 2rem;
        justify-content: flex-start;
      }

      &.is-submit-button-container {
        padding-left: 0;
        margin-top: 1rem;
        justify-content: space-between;

        .csv-button-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .csv-button {
            width: 160px;
            margin-left: 1rem;

            &.csv-upload {
              background-color: #259afa;
              border-color: #259afa;
            }
          }
        }
      }

      .branch-index-field {
        width: 60px;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        display: flex;
        align-items: center;
        height: 40px;
        justify-content: center;
      }

      .branch-name-field {
        width: 20%;
        min-width: 200px;
        max-width: 300px;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        text-align: left;
      }

      .floorlist-field {
        width: calc(80% - 60px);
        min-width: 400px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .floor-item-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 1rem;

          &.is-dragging {
            background-color: @custom-selected-color;
            opacity: 0.7;
          }

          &.add-floor-button-container {
            justify-content: flex-start;
            margin-left: 0.25rem;
            margin-top: 0.5rem;
            width: 100%;
          }

          .floor-order-field {
            width: 120px;
            min-width: 120px;
            text-align: center;
          }

          .floor-name-field {
            width: 25%;
            min-width: 180px;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
          }

          .floor-related-field {
            min-width: 250px;

            .p-inputwrapper {
              width: 100%;
            }
            .p-inputtext {
              justify-content: flex-start;
            }
          }

          .floor-map-field {
            width: 260px;
            min-width: 260px;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .empl-image-preview-field {
              margin-right: 1rem;
              min-width: 60px;
              border: 2px solid #0000005c;
              border-radius: @custom-border-radius;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #0000005c;
              background-color: white;
            }

            .p-fileupload {
              display: flex;
              flex-direction: row-reverse;
              padding: 0;
              margin-left: 1rem;
              height: 38px;
              font-size: 0.9rem;
              border: 2px solid #0000005c;
              border-radius: @custom-border-radius;
              justify-content: center;
              background-color: white;

              .p-fileupload-buttonbar {
                padding: 0;
                display: flex;
                flex-wrap: nowrap;
                border: 0;
                background: transparent;
                gap: 0;
                width: 70px;
                margin-left: auto;
                margin-right: auto;

                .p-button {
                  border: 0;
                  background: transparent;
                  color: @custom-selected-color;
                  height: 100%;
                  margin-right: auto;
                  margin-left: auto;
                }
              }

              .p-fileupload-content {
                padding: 0;
                border: 0;
                background: transparent;
                width: 100%;
                display: none;

                .p-progressbar {
                  display: none;
                }

                .p-fileupload-row {
                  .item-template {
                    height: 100%;
                    padding: 0 0.25rem;
                    font-weight: 600;
                  }
                }

                .p-message {
                  height: 100%;
                  border-radius: 0;
                  margin: 0;

                  .p-message-wrapper {
                    padding: 0;
                    height: 34px;
                    font-size: 0.875rem;
                  }
                }
              }
            }
          }

          .floor-scale-field {
            width: 100px;
            min-width: 100px;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
          }

          .floor-action-field {
            width: 60px;
            min-width: 60px;
          }
        }
      }

      .submit-button {
        margin-left: 1rem;
        margin-right: 1rem;
        width: 100px;
      }

      .add-button {
        width: 100px;
      }

      .input-text-field-container {
        position: relative;

        .p-inputtext {
          width: 100%;
        }

        .validation-error-message {
          font-size: 0.75rem;
          position: absolute;
          bottom: -1.1rem;
          left: 0.5rem;
          color: @custom-red;
        }
      }
    }
  }
}
