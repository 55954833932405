@import "@/styles/_variables.less";

.footer-component-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .footer-content {
        text-align: center;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            color: white;
            text-decoration: none;
        }

        img {
            height: 15px;
            padding-left: 3px;
        }
    }

    .build-version-container {
        font-size: 11px;
        text-align: center;
    }
}