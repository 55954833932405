@import '@/styles/_variables.less';

.employee-terminal-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;
    overflow: scroll;

    .main-content {
      max-height: calc(100vh - 400px);
      overflow: scroll;

      .main-row-container {
        border-bottom: 1px solid #dddddd;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .main-row-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      padding: 0 0.5rem 1rem 0.5rem;
      min-height: 40px;

      &.is-header {
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
        color: #1A1A1C;
        font-weight: 600;
        text-align: center;
        min-height: unset;

        .empl-device-list-container {
          flex-direction: row;
          min-height: 40px;
          align-items: center;
        }
      }

      &.is-add-new-button-container {
        margin-top: 2rem;
        justify-content: flex-start;
      }

      &.is-submit-button-container {
        padding-left: 0;
        margin-top: 1rem;
        justify-content: space-between;

        .csv-button-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .csv-button {
            width: 160px;
            margin-left: 1rem;

            &.csv-upload {
              background-color: #259AFA;
              border-color: #259AFA;
            }
          }
        }
      }

      .empl-name-field {
        width: 15%;
        min-width: 160px;
        max-width: 200px;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        display: flex;
        align-items: center;
        text-align: left;
        font-weight: 600;
        min-height: 40px;
        font-size: 0.875rem;
      }

      .empl-number-field {
        min-width: 100px;
        font-weight: 600;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        display: flex;
        align-items: center;
        min-height: 40px;
      }

      .empl-device-list-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        .empl-device-list-row {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
        }

        .add-meraki-button-container {
          margin-left: 0.25rem;
        }

        .empl-device-mac-field {
          width: 40%;
          min-width: 200px;
          max-width: 400px;
          padding-left: 0.25rem;
          padding-right: 0.25rem;
        }

        .empl-device-name-field {
          width: 40%;
          min-width: 200px;
          max-width: 400px;
          padding-left: 0.25rem;
          padding-right: 0.25rem;
        }

        .empl-device-type-field {
          width: 120px;
          min-width: 120px;
          padding-left: 0.25rem;
          padding-right: 0.25rem;

          .p-dropdown {
            width: 100%;
          }
        }

        .action-field {
          width: 60px;
          min-width: 60px;
        }
      }

      .submit-button {
        margin-left: 1rem;
        margin-right: 1rem;
        width: 100px;
      }

      .add-button {
        width: 100px;
      }

      .input-text-field-container {
        position: relative;

        .p-inputtext {
          width: 100%;
        }

        .validation-error-message {
          font-size: 0.75rem;
          position: absolute;
          bottom: -1.1rem;
          left: 0.5rem;
          color: @custom-red;
        }
      }
    }
  }
}