@import "@/styles/_variables.less";

.username.username-mobile {
  height: calc(@header-height - 8px);
  display: flex;

  .username-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .username-info {
      .username-name {
        font-weight: 600;
        letter-spacing: 0.46px;
        text-transform: capitalize;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        color: white;
        margin: 0;
        font-size: 0.875rem;
        margin-left: 0.5rem;
      }
    }

    .toggle-button-container {
      .p-button {
        color: white;
      }
    }
  }
}