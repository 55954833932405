.action-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-right: 20px;

  .btn-primary {
    margin-right: 10px;
  }
}
