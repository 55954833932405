@import '@/styles/_variables.less';

.config-container {
  &.device-status-overview {
    padding: 1rem;
    align-items: flex-start;

    @media (max-width: 850px) {
      padding: 0;
    }

    .config-title-container {
      .text-left {
        min-width: fit-content;
      }

      @media (max-width: 850px) {
        display: flex;
        flex-wrap: wrap;
      }

      .config-custom-container {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media (max-width: 850px) {
          margin-top: 16px;
        }

        .multiselect-container {
          @media (max-width: 850px) {
            width: calc(100% - 90px);
          }
        }
      }
    }

    .config-content {
      justify-content: flex-start;
      overflow: hidden;

      .p-tabview {
        width: 100%;

        .p-tabview-nav-content {
          .p-tabview-nav {
            @media (max-width: 850px) {
              justify-content: center;
            }
          }

          button {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }
        }

        .p-tabview-panels {
          padding: 1rem 0.5rem 0.5rem 0.5rem;

          @media (max-width: 850px) {
            padding: 0;
          }
        }
      }
    }

    .main-container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      @media (max-width: 850px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }

      .image-container {
        width: calc(100% - 670px);
        padding: 0.25rem;

        @media (max-width: 850px) {
          width: 100%;
          margin-top: 32px;
          padding: 0;
        }

        .image-marker-tools {
          padding-top: 0;
          padding-bottom: 0;
          width: 160px;
          margin-left: auto;
        }

        .image-marker {
          img {
            height: auto;
            width: 100%;
          }
        }
      }

      .table-container {
        width: 670px;
        padding: 0.25rem;

        @media (max-width: 850px) {
          width: 100%;
          padding: 0;
        }

        &.history {
          width: 100% !important;
        }

        .status-icon {
          width: 28px;
          height: 28px;
          background-color: white;
          border: 1px solid #555555;
          border-radius: 50%;
          margin-left: auto;
          margin-right: auto;

          &.status-online {
            background-color: #008000;
          }

          &.status-offline {
            background-color: #ff0000;
          }

          &.status-dormant {
            background-color: #d3d3d3;
          }

          &.status-alerting {
            background-color: #ffd700;
          }

          &.status-unknown {
            background-color: #000000;
          }
        }

        .p-filter-column {
          .p-inputtext {
            padding: 0.25rem 0.5rem;
            border: 1px solid #d4d4d8;
            height: 34px;
          }

          .p-column-filter-element {
            width: 100%;
          }

          .p-column-filter-menu-button {
            display: none;
          }

          .p-column-filter-clear-button {
            display: none;
          }
        }
      }
    }
  }
}