@import '@/styles/_variables.less';

.config-container {
  &.people-counts-analysis {
    padding: 1rem;
    align-items: flex-start;

    .config-content {
      justify-content: flex-start;
    }

    .main-container {
      width: 100%;
      display: flex;
      align-items: flex-start;

      @media (max-width: @mobile-size) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
      }
      .people-counts-chart-container {
        width: 100%;
        padding: 0.5rem 0.5rem;

        .menu-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 2rem;

          .timerange-select-container {
            background: transparent;
            transition: none;
            border-radius: 0.375rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          span {
            &.p-multiselect-token-label {
              width: 100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .select-btn-container {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 20px;

          .empty-download-btn-container {
            flex: 1;
          }

          .select-chart-btn-container {
            flex: 1;
          }

          .download-btn-container {
            text-align: right;
            flex: 1;
          }
        }

        .data-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .data-container__item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 0;
            padding: 0.5rem 0rem;
            width: 100%;

            @media (max-width: @mobile-size) {
              width: 100%;
              padding: 0;
            }

            .chart-container {
              margin-top: 0.5rem;
              width: 100%;
              border: 0;

              .recharts-responsive-container {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}