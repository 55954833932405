@import "@/styles/_variables.less";

.network-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;

  .custom-legend-button {
    padding: 0;
    margin-left: 1rem;
    margin-top: 1px;
    color: #333333;
  }
}


.custom-legend-tooltip.p-tooltip.p-component {
  .data-container-legend {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .legend-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      flex-wrap: nowrap;

      .legend-header {
        font-weight: bold;
        padding: 0.25rem;
        width: 100%;
        text-align: center;
      }

      .legend-content {
        margin-top: 0.5rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        flex-wrap: wrap;

        .item-row {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          padding-top: 0.125rem;
          padding-bottom: 0.125rem;
          gap: 5px;

          .item-name-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 130px;

            .item-color {
              width: 20px;
              height: 20px;
              border: 1px solid #333333;
            }

            .item-name {
              margin-left: 0.5rem;
            }
          }

          .item-value {
            margin-bottom: 0.125rem;
            font-weight: bold;
            min-width: 36px;
          }
        }

        .legend-floormap {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 180px;
          width: 160px;

          @media (max-width: 1200px) {
            margin-top: 8px;
          }

          @media (max-width: (@mobile-size)) {
            width: 50%;
            min-width: 180px;
            justify-content: center;
            margin-top: 16px;
          }

          .sample-marker {
            width: 50px;
            min-width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #555555;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #00A86B;

            .text-count {
              font-size: 1.85rem;
              font-weight: bold;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 0.25rem;
            }
          }

          .floormap-text {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            margin-left: 0.75rem;
          }
        }

        .legend-snr {
          margin-top: 0.5rem;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          width: 100%;
          flex-wrap: wrap;
        }
      }
    }
  }
}