@import '@/styles/_variables.less';

.attendance-rate-setting-container {
  .config-title-container {
    padding-bottom: 0.5rem;
  }

  .setting-content {
    width: 100%;

    .main-content {
      max-height: calc(100vh - 360px);
      height: calc(100vh - 360px);
      overflow: auto;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: @mobile-size) {
        min-width: 1280px;
      }
    }

    .main-row-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 1280px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 0px 16px 19px 16px;

      &.is-dragging {
        background-color: @custom-selected-color;
        opacity: 0.7;
      }

      &.is-header {
        font-size: 0.875rem;
        color: #1a1a1c;
        font-weight: 700;
        text-align: left;
        position: sticky;
        z-index: 1;
        background-color: transparent;
        gap: 55px;

        .select-branch-field,
        .select-floor-field {
          height: 28px;
          max-width: 150px;
        }

        .select-department-field {
          height: 28px;
          max-width: 218px;
        }

        .input-branch-field,
        .input-floor-field,
        .input-department-field {
          height: 28px;
          max-width: 150px;
        }
      }

      &.is-row {
        font-size: 0.875rem;
        color: #1a1a1c;
        font-weight: 600;
        text-align: left;
        position: sticky;
        z-index: 1;
        background-color: transparent;
        gap: 81px;
        padding: '0px 22px 19px 22px';
      }

      .is-group {
        width: 327px;
      }

      .is-select-group {
        width: 190px;
      }

      .is-select-department-group {
        width: 260px;
      }

      .is-column {
        gap: 17px;
        padding-bottom: 24px;
        display: flex;
      }

      &.is-submit-button-container {
        margin-top: 1rem;
        justify-content: flex-start;

        .add-new-button {
          width: 100px;
        }
      }

      .select-branch-field,
      .select-floor-field {
        width: 100%;
        max-width: 190px;
      }

      .select-department-field {
        width: 100%;
        max-width: 260px;
      }

      .input-branch-field,
      .input-floor-field,
      .input-department-field {
        width: 100%;
        max-width: 190px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .p-inputtext {
          text-align: center;
        }
      }

      .action-field {
        .p-component.p-button {
          width: 40px;
          min-width: 40px;
          box-shadow: none;
          padding: 0;
        }

        .icon-only-delete-button {
          color: @custom-red;
          &:hover {
            background: transparent;
          }
        }
      }

      .submit-button {
        margin-left: 0;
        margin-right: 1rem;
        width: 100px;
      }

      .add-button {
        width: 100px;
      }

      .input-text-field-container {
        position: relative;

        .p-inputtext {
          width: 63px;
          overflow: hidden;
          min-width: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          justify-content: flex-start;
          box-shadow: none;
        }

        .validation-error-message {
          font-size: 0.75rem;
          position: absolute;
          bottom: -1.1rem;
          left: 0.5rem;
          color: @custom-red;
        }
      }
    }
  }
}
