.fp-tabs-head-editor {
  display: inline-block;
  input {
    display:none;

  }

  .check-handler,.edit-handler {
    margin-left:20px;
  }

  .check-handler {
    display: none;
  }

  input {
    border: 1px solid #ccc;
    padding:8px 15px;
    outline:none;
    box-shadow:none;
  }
}
